import React, { useContext, useEffect, useState } from "react";
import { Menu, Popover } from "antd";
import "./appSidebar.scss";
import { generatePath, useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import sideBarLogo from "../../../assets/sidebar-logo.png";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import UserService from "../../../services/AuthService/auth.service";
import { SHOW_HOSPITAL } from "../../../routes/routeConstants/appRoutes";
import StaffBasicDetails from "../StaffBasicDetails";
import DpChip from "../DpChip";
import HospitalService from "../../../services/HospitalService/hospital.service";
import { checkPermission } from "../../utils/checkPermission";
import { CustomAccess } from "../../../models/CustomAccess/customAccess.model";
import { CustomAccessContext } from "../../../context/CustomAccessContext";
import PermissionBasedRestriction from "../PermissionBasedRestriction";

interface AppSidebarProps extends AuthReducerProps { }

function AppSidebar({ authenticated, user }: AppSidebarProps) {
  const history = useHistory();

  const { logoutUser } = UserService();

  const {
    showHospital,
    hospital,
  } = HospitalService()

  const [pathname, setPathname] = useState<string>(history.location.pathname);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  useEffect(() => {
    user?.hospitalId && showHospital(user?.hospitalId)
  }, [user?.hospitalId])

  useEffect(() => {
    return history.listen((location) => {
      setPathname("/" + location?.pathname?.split("/")[1]);
    });
  }, [history]);

  const handleClick = ({ key }: any) => {
    if (key !== "profile") history.push(key);
  };

  const customAccessDetails: CustomAccess = useContext(CustomAccessContext)

  const profileContent = (
    <div className="profile-menu">
      <div
        className="profile-menu__item"
        onClick={() => setShowDetailsModal(true)}
      >
        <i className="icon-my-account" />
        <span className="app-sidebar__menu-title">My Account</span>
      </div>
      <PermissionBasedRestriction
        entityName="hospitalAccess"
        entityPermission="read"
      >
        <div
          className="profile-menu__item"
          onClick={() => {
            history.push(generatePath(SHOW_HOSPITAL, { id: user?.hospitalId }));
          }}
        >
          <i className="icon-hospitals" />
          <span className="app-sidebar__menu-title">Hospital profile</span>
        </div>
      </PermissionBasedRestriction>
      <div className="profile-menu__item" onClick={logoutUser}>
        <i className="icon-log-out" />
        <span className="app-sidebar__menu-title">Log out</span>
      </div>
    </div>
  );

  const hasPaitentAccess = (checkPermission(customAccessDetails, 'patientAccess', 'read') || checkPermission(customAccessDetails, 'caregiverAccess', 'read'))

  const menuItems = [
    {
      key: AppRoutes.DASHBOARD,
      pathName: "dashboard",
      menuName: "Dashboard",
      iconName: "icon-Union-14",
      hasAccess: true
    },
    {
      key: AppRoutes.PATIENTS,
      pathName: "patient",
      menuName: "Patient",
      iconName: "icon-my-account",
      hasAccess: hasPaitentAccess
    },
    {
      key: AppRoutes.STAFFS,
      pathName: "staff",
      menuName: "Teams",
      iconName: "icon-members",
      hasAccess: checkPermission(customAccessDetails, 'staffAccess', 'read')
    },
    {
      key: AppRoutes.JOURNEYS,
      pathName: "journeys",
      menuName: "Journey",
      iconName: "icon-journey",
      hasAccess: checkPermission(customAccessDetails, 'journeyAccess', 'read')
    },
    {
      key: AppRoutes.CHATS,
      pathName: "chat",
      menuName: "Chat",
      iconName: "icon-chat",
      hasAccess: checkPermission(customAccessDetails, 'chatAccess', 'read')
    },
    {
      key: AppRoutes.ARTICLES,
      pathName: "content",
      menuName: "Content",
      iconName: "icon-articles",
      hasAccess: checkPermission(customAccessDetails, 'articleAccess', 'read')
    },
    {
      key: AppRoutes.FAQ,
      pathName: "faq",
      menuName: "FAQ",
      iconName: "icon-faq",
      hasAccess: checkPermission(customAccessDetails, 'faqAccess', 'read')
    },
    {
      key: AppRoutes.JOURNEY_TEMPLATES,
      pathName: "journey-templates",
      menuName: "Journey Template",
      iconName: "icon-journey-template",
      hasAccess: checkPermission(customAccessDetails, 'journeyTemplateAccess', 'read')
    },
    {
      key: AppRoutes.USERS,
      pathName: "user",
      menuName: "User & Roles",
      iconName: "icon-management",
      hasAccess: user?.role === 'Hospital Admin'
    }
  ]

  return !history?.location?.pathname?.includes("auth") && authenticated ? (
    <div className="app-sidebar">
      {user && <StaffBasicDetails
        staff={user}
        setShowDetails={setShowDetailsModal}
        showDetails={showDetailsModal}
        setCurrentStaff={() => { }}
        enablePasswordReset={true}
      />}
      <div className="app-sidebar__menubar__logo">
        <img src={sideBarLogo} alt="" />
      </div>
      <Menu
        className="app-sidebar__menubar"
        onClick={handleClick}
        defaultSelectedKeys={["dashboard"]}
        selectedKeys={[pathname]}
        mode="inline"
      >
        {
          menuItems.map((item) =>
            item.hasAccess
              ? <Menu.Item key={item.key}>
                <i
                  className={`${item.iconName} ${pathname.includes(item.pathName) ? "active-icon" : ""
                    }`}
                />
                <div className="app-sidebar__menu-title">{item.menuName}</div>
              </Menu.Item>

              : null

          )
        }

        {/*  // will be used later in phase 2
         {user?.role !== "Hospital Admin" && (
          <Menu.Item key={AppRoutes.CALENDAR}>
            <i
              className={`icon-Calander ${pathname.includes("calendar") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Calendar</div>
          </Menu.Item>
        )} */}
      </Menu>
      <Popover
        placement="right"
        title={null}
        content={profileContent}
        trigger="hover"
      >
        <div className="app-sidebar__profile-container">
          <div className="app-sidebar__profile" key="profile">
            <div>
              <DpChip name={user?.name ?? user?.firstName + " " + user?.lastName}
                size="large"
                url={user?.displayPictureUrl}
                userId={user?.id}
              />
            </div>
            <div className="app-sidebar__profile-info">
              <div className="app-sidebar__profile-submenu">

                <div className="app-sidebar__username text-capitalize">
                  {user?.title + " " + user?.name ?? user?.firstName + " " + user?.lastName}
                </div>
                <p className="app-sidebar__hospital text-capitalize">
                  {hospital.name}
                </p>
              </div>
              <span className="app-badge">
                {user?.role}
              </span>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  ) : null;
}

export default AuthContainer(AppSidebar);
