import React, { FC, useEffect, useState } from 'react'
import "./permissionDetails.scss"
import { Col, Row } from 'antd'
import InputField from '../../../../shared/components/InputField'
import { Form, Formik } from 'formik'
import PermissionRowDetails from './PermissionRowDetails'
import permissionData from './defaultPermissions'
import DeleteConfirmation from '../../../../shared/components/DeleteConfirmation'
import CustomAccessService from '../../../../services/CustomAccessService/customAccess.service'
import SkeletonLoader from '../../../../shared/components/SkeletonLoader'
import MetaService from '../../../../services/MetaService/meta.service'
import { permissionFormValidationSchema } from './permissionFormValidation'

interface PermissionDetailsProps {
    fetchEmployeeRoleList: () => {},
    roleId: string
}

const PermissionDetails: FC<PermissionDetailsProps> = (props) => {

    const { fetchEmployeeRoleList, roleId } = props

    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [deleteRoleId, setDeleteRoleId] = useState<string | undefined>()

    const removeItemsList = ['id', 'name']
    const permissionList = Object.keys(permissionData).filter((item) => !removeItemsList.includes(item))

    const { showCustomAccess, customAccess, loading, updateCustomAccess } = CustomAccessService()
    const { fetchEmployeeRole, employeeRole, loading: employeeRoleLoading, updateEmployeeRole, deleteEmployeeRole, buttonLoading } = MetaService()

    useEffect(() => {
        if (!roleId) return
        fetchEmployeeRole(roleId || "")
        showCustomAccess(roleId || "")
    }, [roleId])

    if (!roleId) return null

    if (loading || employeeRole === undefined) {
        return <SkeletonLoader avatar={false} rows={4} />
    }

    const blockedList = ['Hospital Admin', 'Clinical Staff']

    const shouldBlock = blockedList.includes(employeeRole.label ?? "")

    return (
        <div className={"permission-details checkbox-as-radio " + (isEditing ? " " : " disabled")}>
            <Formik
                enableReinitialize
                initialValues={{
                    ...permissionData,
                    ...customAccess,
                    name: employeeRole?.label,
                    employeeRoleName: employeeRole?.value
                }}
                validationSchema={permissionFormValidationSchema}
                onSubmit={async (values) => {
                    await updateEmployeeRole({
                        label: values.name,
                        value: roleId
                    })
                    await updateCustomAccess(values)
                    setIsEditing(false)
                    await fetchEmployeeRoleList()
                }}
            >
                {({ resetForm, submitForm, isSubmitting }) => (
                    <Form>
                        <div className='role-title'>
                            {
                                isEditing
                                    ? <>
                                        <InputField
                                            type="text"
                                            name="name"
                                            placeholder={''}
                                        />

                                        <div>
                                            <span className='mr-5 text-secondary' onClick={() => {
                                                resetForm()
                                                setIsEditing(false)
                                            }}>Cancel  </span>
                                            <span className='text-primary' onClick={() => {
                                                submitForm()
                                            }}>
                                                {
                                                    isSubmitting ? "Saving" : "Save"
                                                }
                                            </span>
                                        </div>
                                    </>
                                    : <>
                                        <div>{employeeRole?.label}</div>
                                        {shouldBlock
                                            ? null
                                            : <div>
                                                <span>
                                                    <i className="icon-edit-1" onClick={() => setIsEditing(true)} />
                                                </span>
                                                <span>
                                                    <i className="icon-delete" onClick={() => setDeleteRoleId(roleId)} />
                                                </span>
                                            </div>
                                        }
                                    </>

                            }
                        </div>

                        <Row className='permission-header'>
                            <Col span={4}>
                                <div>Feature</div>
                            </Col>
                            <Col span={4} offset={1}>
                                <div className='text-center'> Create </div>
                            </Col>
                            <Col span={5}>
                                <div className='text-center'>  Read</div>
                            </Col>
                            <Col span={5}>
                                <div className='text-center'>Update </div>
                            </Col>
                            <Col span={5}>
                                <div className='text-center'> Delete </div>
                            </Col>
                        </Row>
                        {
                            permissionList.map((item, index) =>
                                <PermissionRowDetails key={index} fieldName={item} />
                            )
                        }
                    </Form>
                )}
            </Formik>
            <DeleteConfirmation
                showConfirmation={deleteRoleId !== undefined}
                setShowConfirmation={() => {
                    setDeleteRoleId(undefined)
                }}
                entityName={'Role'}
                handleDelete={async () => {
                    await deleteEmployeeRole(deleteRoleId ?? "")
                    await fetchEmployeeRoleList()
                    setDeleteRoleId(undefined)
                }}
                buttonLoading={buttonLoading}
            />
        </div>
    )
}

export default PermissionDetails