export default {
    "name": "",
    "hospitalAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "hosDepartmentAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "staffAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "caregiverAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "patientAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "journeyAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "journeyTemplateAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "articleAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "faqAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "tagsAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
    "chatAccess": {
        "create": false,
        "update": false,
        "read": false,
        "delete": false
    },
}