
import {Employees} from '../Employees/employees.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class JourneyDashboard { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('patient', primitive()))
	patient?: string;

	@serializable(alias('guardian', primitive()))
	guardian?: string;

	@serializable(alias('employees', list(object(Employees))))
	employees?: Employees[] = [];

}