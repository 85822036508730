import React, { FC, useEffect, useState } from 'react'
import "./clinicalStaffDashboard.scss"
import { Tabs } from "antd";
import SearchComponent from "../../../shared/components/SearchComponent";
import TableComponent from "../../../shared/components/TableComponent";
import DashboardService from '../../../services/DashboardService/dashboard.service';
import patientRequestColumns from './patientRequestColumns';
import { generatePath, useHistory } from 'react-router-dom';
import fetchColumns from './upcomingEventColumn';
import { PatientRequest } from '../../../models/PatientRequest/patientRequest.model';
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import JourneyService from '../../../services/JourneyService/journey.service';
import { Journey } from '../../../models/Journey/journey.model';

const { TabPane } = Tabs;

interface ClinicalStaffDashboardProps {

}

const ClinicalStaffDashboard: FC<ClinicalStaffDashboardProps> = (props) => {
  const { } = props;
  const history = useHistory()

  const [currentView, setCurrentView] = useState<string>('event')
  const [searchText, setSearchText] = useState<string>('');

  const { fetchUpcomingEvents, upcomingEvent, fetchPatientRequest, patientRequest, loading, patientRequestLoading } = DashboardService()
  const { updateJourney } = JourneyService()

  const channgeStatus = async (status: string, data: Journey) => {
    data.status = status
    await updateJourney(data, data?.id?.toString() ?? "")
    await fetchUpcomingEvents()
  }

  useEffect(() => {
    fetchUpcomingEvents()
  }, [])

  const navigateToJourneyDetails = (record: PatientRequest) => {
    const path = generatePath(appRoutes.JOURNEY_DETAILS, { id: record.journeyId })
    history.push({
      pathname: path,
      state: {
        category: record.journeyCategoryId,
        step: record.stepId
      }
    })
  }

  useEffect(() => {
    setSearchText("")
    if (currentView === 'event') {
      fetchUpcomingEvents()
    } else {
      fetchPatientRequest()
    }
  }, [currentView])

  useEffect(() => {
    if (currentView === 'event') {
      fetchUpcomingEvents({ search: searchText })
    } else {
      fetchPatientRequest({ search: searchText })
    }

  }, [searchText])

  const upcomingeventColumns = fetchColumns(channgeStatus)

  return (
    <div className="clinical-staff-dashboard">
      <div className="clinical-staff-dashboard__search">
        <SearchComponent onSearch={() => { }} searchValue={searchText} setSearchValue={setSearchText}
          onReset={() => setSearchText("")}
        />
      </div>
      <Tabs activeKey={currentView} defaultActiveKey={currentView} onChange={(activeKey: string) => setCurrentView(activeKey)}>
        <TabPane tab="Upcoming events" key="event">
          <TableComponent
            key={'dashboard-event-table'}
            data={upcomingEvent}
            columns={upcomingeventColumns}
            loading={loading}
            isNoPagination
            onPageChange={() => { }}
            total={2}
            currentPage={1}
          />
        </TabPane>
        <TabPane tab="Patient requests" key="request">
          <TableComponent
            key={'dashboard-request-table'}
            data={patientRequest}
            columns={patientRequestColumns}
            loading={patientRequestLoading}
            isNoPagination
            onPageChange={() => { }}
            total={2}
            currentPage={1}
            handleRowClick={navigateToJourneyDetails}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ClinicalStaffDashboard;