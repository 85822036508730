import React, { FC, useEffect, useRef, useState } from "react";
import "./journeyDetailHeader.scss";
import { ArrowLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { generatePath, useHistory } from "react-router";
import userPlaceholder from "../../../../assets/userPlaceholder.png";
import { Journey } from "../../../../models/Journey/journey.model";
import SkeletonLoader from "../../../../shared/components/SkeletonLoader";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { EllipsisOutlined, MailOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Modal } from "antd";
import JourneyService from "../../../../services/JourneyService/journey.service";
import JourneyStatusForm from "./JourneyStatusForm";
import DpChip from "../../../../shared/components/DpChip";
import { Staff } from "../../../../models/Hospital/staff.model";
import StaffBasicDetails from "../../../../shared/components/StaffBasicDetails";
import { useLocation } from "react-router-dom";
import { getFullName } from "../../../../shared/utils/dataFormatConverter";
import GoBack from "../../../../shared/components/GoBack";
import JourneyPatientCard from "../../../../shared/components/JourneyPatientCard";
import PermissionBasedRestriction from "../../../../shared/components/PermissionBasedRestriction";


interface JourneyDetailHeaderProps {
  journey?: Journey;
  tableLoading?: boolean;
  refreshJourey: () => void
}

const JourneyDetailHeader: FC<JourneyDetailHeaderProps> = (props) => {
  const { journey, tableLoading, refreshJourey } = props;

  const history = useHistory();
  const { state } = useLocation<{ currentPage: number, search: string }>();
  const page = state ? state.currentPage : 1;
  const search = state ? state.search : "";
  const { publishJourney, updateJourney } = JourneyService();

  const [showStatusModal, setShowStatusModal] = useState(false);

  const [currentStaff, setCurrentStaff] = useState<Staff>();

  const [showAllMembers, setShowAllMembers] = useState(false);

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const [showOverflow, setShowOverflow] = useState<boolean>(
    // !!journey?.description && journey?.description?.length > 90
    false
  );

  const descriptionRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (descriptionRef.current) {
        descriptionRef.current.innerHTML = journey?.description?.replace(/\n/gm, '<br />') || "";
        descriptionRef.current.innerHTML = journey?.description?.replace(/<p>&nbsp;<\/p>/i, ' ') || "";
        descriptionRef.current.innerHTML = journey?.description?.replace(/<br>/gm, ' ') || "";
      }
    })
  }, [journey?.description, descriptionRef.current])

  const handleToggleStatusModal = () => setShowStatusModal(!showStatusModal);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() =>
          history.push(
            generatePath(AppRoutes.JOURNEY_FORM, { id: journey?.id })
          )
        }
      >
        Edit
      </Menu.Item>
      <Menu.Item key="2" onClick={handleToggleStatusModal}>
        Change Status
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="journey-detail-header">
      {tableLoading ? (
        <SkeletonLoader rows={5} />
      ) : (
        <>
          <div className="journey-detail-header__title">
            <GoBack route={{ pathname: generatePath(AppRoutes.JOURNEYS), search: `?page=${page}${search !== '' ? `&search=${search}` : ''}`, state: { currentPage: page, search: search } }} />
            <div className="journey-detail__name text-capitalize">
              {journey?.name}
            </div>
            <PermissionBasedRestriction
              entityName={"journeyAccess"}
              entityPermission={"update"}
            >
              <div className="edit-btn" >
                <Dropdown overlay={menu}>
                  <i className="icon-edit-1"></i>
                </Dropdown>
              </div>
            </PermissionBasedRestriction>
          </div>
          <div className="journey-detail-header__desc">
            <span
              ref={descriptionRef}
              className={showOverflow ? "text-overflow" : ""} />
            {journey?.description && journey?.description?.length > 90 && (
              <span
                className="view-more"
                onClick={() => setShowOverflow(!showOverflow)}
              >
                {showOverflow ? "View more" : "View less"}
              </span>
            )}
          </div>
          <div className="journey-detail-header__desc mt-5">
            <p>{journey?.tags?.map((tag) => tag.label)?.join(", ")}</p>
          </div>
          {journey?.patient && journey?.hospital?.id && <div className="journey-detail-header__team-info">
            <div className="journey-detail-header__team-title mb-2">
              Patient
            </div>
            <JourneyPatientCard hospitalId={journey.hospital?.id} patient={journey?.patient} />
          </div>}
          <div className="journey-detail-header__team-info">
            <div className="journey-detail-header__team-title">
              Clinical team
            </div>
            {journey?.staffs &&
              journey?.staffs?.length > 0 &&
              journey?.staffs?.slice(0, showAllMembers ? journey.staffs.length : 3).filter((staff, i) => staff.status)?.map((staff, i) => (
                <div className="journey-detail-header__staff" key={i}
                  onClick={(e) => {
                    e.stopPropagation()
                    setCurrentStaff(staff);
                    setShowDetails(true);
                  }}>
                  <div className="journey-detail-header__staff__image">
                    {/* <img
                      src={ ?? userPlaceholder}
                      alt=""
                    /> */}
                    <DpChip size="mini" name={getFullName(staff)}
                      url={staff?.displayPictureUrl} />
                  </div>
                  <div className="journey-detail-header__staff__details">
                    <div className="staff-name text-capitalize">{getFullName(staff)}</div>
                    <div className="staff-spl">{staff?.role?.label}</div>
                  </div>
                </div>
              ))}
            {!showAllMembers
              ? journey?.staffs && journey?.staffs?.length > 3 && (
                <div
                  className="journey-detail-header__staff text-center"
                  key={"count"}
                  onClick={() => setShowAllMembers(true)}
                >
                  <div>
                    {journey?.staffs ? `${journey?.staffs?.length - 3}+` : ""}
                  </div>
                  Members
                </div>
              )
              : <span className="collapse-members" onClick={() => setShowAllMembers(false)}>
                <LeftOutlined />
              </span>
            }
          </div>
        </>
      )}
      <Modal
        title={null}
        footer={null}
        width="25%"
        visible={showStatusModal}
        maskClosable={false}
        centered
        destroyOnClose
        onCancel={handleToggleStatusModal}
        className="journey-status-modal"
      >
        <JourneyStatusForm
          onSuccess={(resp) => {
            setShowStatusModal(false);
            resp && refreshJourey()
          }}
          journey={journey} />
      </Modal>
      {currentStaff && <StaffBasicDetails
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        staff={currentStaff}
        setCurrentStaff={setCurrentStaff}
      />}
    </div>
  );
};

export default JourneyDetailHeader;
