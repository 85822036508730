import { CustomAccess } from "../../models/CustomAccess/customAccess.model";
import { Permissions } from "../../models/Permissions/permissions.model";
import { getUserDataFromLocalStorage } from "./getUserDataFromLocalStorage";

export const checkPermission = (customAccessDetails: CustomAccess, entityName: keyof CustomAccess, entityPermission: keyof Permissions
) => {
    let hasAccess: boolean = true;

    try {
        const entityPermissionDetails = customAccessDetails[entityName]
        const { role } = getUserDataFromLocalStorage()
    
        if(role === 'Hospital Admin' || role === 'Clinical Staff'){
            return hasAccess
        }
    
        if (!customAccessDetails) return hasAccess
    
        if (typeof (entityPermissionDetails) === 'object') {
            hasAccess = entityPermissionDetails[entityPermission] ?? false
        }
    
        return hasAccess
    } catch (error) {
        return false
    }
}