import React, { FC } from 'react';
import ButtonComponent from "../ButtonComponent";
import "./appHeader.scss";
import SearchComponent from "../SearchComponent";
import GoBack from '../GoBack';
import PermissionBasedRestriction from '../PermissionBasedRestriction';
import { CustomAccess } from '../../../models/CustomAccess/customAccess.model';
import { Permissions } from '../../../models/Permissions/permissions.model';

interface AppHeaderProps {
    showGoBack?: boolean;
    iconClassName?: string;
    title: string;
    isSearchEnabled?: boolean;
    buttonText?: string;
    buttonHandler?: () => void;
    searchValue?: string;
    setSearchValue?: Function;
    buttonActionText?: string;
    handleSearch?: Function;
    entityName?: keyof CustomAccess
    entityPermission?: keyof Permissions
}

const AppHeader: FC<AppHeaderProps> = (props) => {
    const { iconClassName, title, isSearchEnabled, buttonText, buttonHandler, handleSearch, showGoBack, searchValue, setSearchValue, buttonActionText } = props;

    let { entityName, entityPermission } = props

    if (!entityName) entityName = "articleAccess"
    if (!entityPermission) entityPermission = "create"

    return (
        <div className="app-header">
            <div className="app-header__left">
                {showGoBack && <GoBack />}
                {iconClassName && <div className={`${iconClassName} app-header__icon`} />}
                <div className="app-header__title">{title}</div>
            </div>
            <div className="app-header__right">
                {isSearchEnabled && <div className="app-header__search">
                    <SearchComponent
                        onSearch={handleSearch ? handleSearch : () => { }}
                        searchValue={searchValue ?? ""}
                        setSearchValue={setSearchValue as Function} />
                </div>}
                {buttonText && <div className="app-header__action">
                    <PermissionBasedRestriction
                        entityName={entityName}
                        entityPermission={entityPermission}                    >
                        <ButtonComponent text={`${buttonActionText ?? 'Add'} ${buttonText}`}
                            className="app-header__button"
                            key={"header-button"}
                            type="primary"
                            onClick={buttonHandler}
                        />
                    </PermissionBasedRestriction>
                </div>}
            </div>
        </div>
    )
}

export default AppHeader;