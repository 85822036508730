import React, { FC } from 'react'
import "./searchComponent.scss"
import Search from "antd/lib/input/Search";

interface SearchComponentProps {
    onSearch: Function;
    searchValue: string;
    setSearchValue: Function;
    onReset?: () => void
}

const SearchComponent: FC<SearchComponentProps> = (props) => {
    const { onSearch, searchValue, setSearchValue, onReset } = props;

    return (
        <div className="search-component">
            <Search placeholder="Search..."
                allowClear
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onSearch={(searchText) => onSearch(searchText)}
                onReset={onReset}
            />
        </div>
    )
}

export default SearchComponent;