import React, { FC, useEffect } from 'react'
import "./userList.scss"
import { useHistory, useLocation } from 'react-router-dom'
import TableComponent from '../../../shared/components/TableComponent'
import StaffService from '../../../services/HospitalService/staff.service'
import columns from './columns'
import StaffForm from '../../StaffsList/StaffForm'

interface UserListProps {
    showStaffForm: boolean
    setShowForm: (showForm: boolean) => void;
}

const UserList: FC<UserListProps> = (props) => {

    const { showStaffForm, setShowForm } = props
    const { tableLoading, fetchStaffs, staffList, currentPage, totalItems } = StaffService();
    const history = useHistory();
    const location = useLocation<{ currentPage: string }>();
    const urlParams = new URLSearchParams(location.search)
    const page = parseInt(urlParams.get('page') || '1');
    const searchText = urlParams.get('search') || '';


    useEffect(() => {
        fetchStaffs({
            search: searchText
        }, page, 10)
    }, [page, searchText])


    const handlePageChange = (page: number) => {
        history.push({ pathname: history.location.pathname, search: `?page=${page}`, state: { currentPage: page } })
    }

    return (
        <div className="user-list">
            <TableComponent loading={tableLoading}
                data={staffList}
                columns={columns}
                onPageChange={handlePageChange}
                currentPage={page}
                total={totalItems}
            />

            {
                showStaffForm && <StaffForm
                    showForm={showStaffForm}
                    setShowForm={setShowForm}
                    refreshList={fetchStaffs}
                    staff={{}}
                />
            }


        </div>
    )
}

export default UserList