import React, { FC, useEffect } from "react";
import "./patients.scss";
import PatientHeader from "../../shared/components/PatientHeader";
import { Caretaker } from "../../models/Patient/patient.model";
import PatientService from "../../services/PatientService/patient.service";
import { useLocation, useParams } from "react-router-dom";
import JourneyService from "../../services/JourneyService/journey.service";
import PatientJourneyHistory from "../../shared/components/PatientJourneyHistory";
import AuthContainer from "../../store/container/AuthContainer";
import { AuthReducerProps } from "../../store/reducers/authReducer";
import RedirectBasedOnThePermission from "../../shared/components/RedirectBasedOnThePermission";

interface PatientsProps extends AuthReducerProps { }

const Patients: FC<PatientsProps> = (props) => {
  const { user } = props;
  const location = useLocation();
  const { id, hospitalId } = useParams<{ id: string, hospitalId: string }>();

  const { patient, showPatient, patientTableLoading } = PatientService();
  const { fetchJourneys, journeyList, tableLoading } = JourneyService();

  useEffect(() => {
    if (id) {
      showPatient(hospitalId, id).then();
      fetchJourneys({ patient_id: id }).then();
    }
  }, [id, location]);

  return (
    <div className="app-wrapper patients">
      <RedirectBasedOnThePermission
        entityName={"patientAccess"}
      >
        <PatientHeader
          patient={patient}
          user={user}
          dependentType={"caretaker"}
          dependents={patient?.caretaker ? [patient?.caretaker] : undefined}
          loading={patientTableLoading}
          fetchPatient={showPatient}
        />
        <PatientJourneyHistory
          journeyList={journeyList}
          cardType={"patient"}
          loading={tableLoading}
        />
      </RedirectBasedOnThePermission>
    </div>
  );
};

export default AuthContainer(Patients);
