import React, { FC, useContext, useEffect, useState } from 'react'
import "./patientsAndCaretakers.scss"
import { Tabs } from 'antd';
import PatientsList from "./PatientsList";
import CareTakerForm from "./CaretakerList/CaretakerForm";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import CaretakerList from './CaretakerList';
import PatientForm from './PatientsList/PatientForm';
import CaretakerService from '../../services/CaretakerService/caretaker.service';
import PatientService from '../../services/PatientService/patient.service';
import AuthContainer from '../../store/container/AuthContainer';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import AppHeader from '../../shared/components/AppHeader';
import { CustomAccessContext } from '../../context/CustomAccessContext';
import { CustomAccess } from '../../models/CustomAccess/customAccess.model';
import { checkPermission } from '../../shared/utils/checkPermission';
import * as AppRoutes from "../../routes/routeConstants/appRoutes";

const { TabPane } = Tabs;

interface PatientsAndCaretakersProps extends AuthReducerProps {

}

const PatientsAndCaretakers: FC<PatientsAndCaretakersProps> = (props) => {
    const { user, } = props;

    const history = useHistory()

    const location = useLocation<any>();
    const urlParams = new URLSearchParams(location.search)
    const page = parseInt(urlParams.get('page') || '1');
    const key = location?.state?.selectedKey || location.hash.substring(1) || "patient";
    const search = urlParams.get('search') || '';
    const showForm = location?.state?.showForm;

    const { fetchPatients, patientTableLoading, patientList, patientTotalItems } = PatientService();
    const { fetchCaretakers, caretakerTableLoading, caretakerList, caretakerCurrentPage, caretakerTotalItems } = CaretakerService();

    const customAccessDetails: CustomAccess = useContext(CustomAccessContext)

    const hasPatientAccess = checkPermission(customAccessDetails, 'patientAccess', 'read');
    const hasCaregiverAccess = checkPermission(customAccessDetails, 'caregiverAccess', 'read');

    const [selectedKey, setSelectedKey] = useState<string>(!hasPatientAccess ? 'caretaker' : key);
    const [showPatientForm, setShowPatientForm] = useState<boolean>(false);
    const [showCatetakerForm, setShowCatetakerForm] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>(search);

    useEffect(() => {
        history.location.hash ?? setSelectedKey(location.hash.replace("#", ""));
        if (selectedKey === "caretaker")
            fetchCaretakers(user?.hospitalId as string, {
                search: searchText,
                page: page,
                pageSize: 10
            });
        else
            fetchPatients(user?.hospitalId as string, {
                search: searchText,
                page: page,
                pageSize: 10
            });
    }, [location])

    useEffect(() => {
        setShowCatetakerForm(!!showForm)
    }, [showForm])

    const handleTabChange = (key: string) => {
        setSelectedKey(key);
        setSearchText('');
        history.push({
            pathname: history.location.pathname,
            hash: key,
            search: "?page=1",
            state: { currentPage: 1 }
        })
    }

    const handleSearch = (searchText: string) => {
        if (!user?.hospitalId) return

        setSearchText(searchText);
        history.push({ ...location, search: `?page=1&search=${searchText}` });

    }

    const handlePageChange = (page: number, pageSize?: number) => {
        if (!user?.hospitalId) return
        history.push({ pathname: history.location.pathname, search: `?page=${page}`, hash: selectedKey, state: { currentPage: page } })
    }

    const isPatient = selectedKey === 'patient'

    const tabPaneList = []

    if (hasPatientAccess) {
        tabPaneList.push({
            tabName: "Patient",
            key: 'patient',
            component: <PatientsList fetchPatients={() => user?.hospitalId && fetchPatients(user?.hospitalId)}
                tableLoading={patientTableLoading}
                patientList={patientList}
                handlePageChange={handlePageChange}
                currentPage={page}
                totalItems={patientTotalItems}
                hospitalId={user?.hospitalId || ''}
            />
        })
    }

    if (hasCaregiverAccess) {
        tabPaneList.push({
            tabName: "Caregiver",
            key: 'caretaker',
            component: <CaretakerList fetchCaretakers={() => user?.hospitalId && fetchCaretakers(user?.hospitalId)}
                tableLoading={caretakerTableLoading}
                caretakerList={caretakerList}
                handlePageChange={handlePageChange}
                currentPage={caretakerCurrentPage}
                totalItems={caretakerTotalItems}
                hospitalId={user?.hospitalId || ''}
            />
        })
    }

    if(!hasPatientAccess && !hasCaregiverAccess){
        return <Redirect to={AppRoutes.STAFFS} />
    }

    return (
        <div className="app-wrapper staffs-and-patients">
            <AppHeader iconClassName="icon-my-account"
                title={isPatient ? "Patient" : "Caregiver"}
                isSearchEnabled={true}
                buttonText={isPatient ? "Patient" : "Caregiver"}
                buttonHandler={() => {
                    selectedKey === "caretaker" ? setShowCatetakerForm(true)
                        : setShowPatientForm(true)
                }}
                searchValue={searchText}
                setSearchValue={setSearchText}
                handleSearch={handleSearch}
                showGoBack={false}
                entityName={isPatient ? "patientAccess" : 'caregiverAccess'}
                entityPermission="create"
            />

            <Tabs activeKey={selectedKey} onChange={handleTabChange}>
                {
                    tabPaneList.map((item) =>
                        <TabPane tab={item.tabName} key={item.key}>
                            {item.component}
                        </TabPane>
                    )
                }
            </Tabs>

            <CareTakerForm showForm={showCatetakerForm}
                setShowForm={setShowCatetakerForm}
                caretaker={{}}
                hospitalId={user?.hospitalId || ''}
                refreshList={() => user?.hospitalId && fetchCaretakers(user?.hospitalId)}
            />

            <PatientForm showForm={showPatientForm}
                setShowForm={setShowPatientForm}
                patient={{}}
                hospitalId={user?.hospitalId || ''}
                refreshList={() => user?.hospitalId && fetchPatients(user?.hospitalId)}
            />
        </div>
    )
}

export default AuthContainer(PatientsAndCaretakers);