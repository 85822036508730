import React, { FC, useState, useEffect, useRef, useContext } from 'react'
import "./articles.scss"
import AppHeader from "../../shared/components/AppHeader";
import TableComponent from "../../shared/components/TableComponent";
import { Journey } from "../../models/Journey/journey.model";
import { generatePath, useLocation } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import DeleteConfirmation from "../../shared/components/DeleteConfirmation";
import { useHistory } from "react-router";
import moment from "moment";
import { Dropdown, Menu, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { priorityMeta } from "../../shared/utils/statusMetas";
import { ArticleModel } from "../../models/Article/article.model";
import ArticleService from "../../services/ArticleService/article.service";
import { checkForEditAnddeletePermission } from '../../shared/utils/checkForEdit&DeletePermission';
import PermissionBasedRestriction from '../../shared/components/PermissionBasedRestriction';
import { CustomAccessContext } from '../../context/CustomAccessContext';
import { CustomAccess } from '../../models/CustomAccess/customAccess.model';
import RedirectBasedOnThePermission from '../../shared/components/RedirectBasedOnThePermission';

interface ArticlesProps {

}

const Articles: FC<ArticlesProps> = (props) => {
    const { } = props;

    const history = useHistory();
    const location = useLocation<{ currentPage: string, search: string }>();
    const page = parseInt(location?.state?.currentPage) || 1;
    const searchText = location?.state?.search || '';
    const { fetchArticles, articleList, currentPage, tableLoading, totalItems, deleteArticle, buttonLoading } = ArticleService();
    const rootRef = useRef<null | HTMLDivElement>(null)

    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [currentArticle, setCurrentArticle] = useState<ArticleModel>(new ArticleModel());
    const [search, setSearch] = useState<string>(searchText);

    useEffect(() => {
        fetchArticles({ search, page, pageSize: 10 }).then();
        rootRef!.current!.scrollIntoView({
            behavior: 'smooth'
        });
    }, [location])

    let columns: any = [
        {
            title: 'TITLE',
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: ArticleModel) => (
                <span className={"text-bold text-capitalize"}>
                    {record?.title}
                </span>
            )
        },
        {
            title: 'AUTHOR NAME',
            dataIndex: 'authorName',
            key: 'authorName',
            render: (text: string, record: any) => <span className="text-capitalize">{text}</span>
        },
        {
            title: 'TAGS',
            dataIndex: 'tags',
            key: 'tags',
            render: (text: string, record: ArticleModel) => (
                <>
                    {record?.tags && record?.tags?.length > 2 ? record?.tags?.slice(0, 2).map(o => o.label).join(',') + ", +" + (record?.tags?.length - 2)
                        : record?.tags?.map(o => o.label).join(',')}
                </>
            )
        },
        {
            title: 'PRIORITY',
            dataIndex: 'priorityId',
            key: 'priorityId',
            render: (text: string, record: ArticleModel) =>
                <Tag key={record?.id}
                    className={`app-table-tags ${record?.priorityId === "neutral" ? 'green' :
                        record?.priorityId === "important" ? 'active' : 'pending'
                        }`}
                >
                    {record?.priorityId ?
                        priorityMeta.find(priority => priority.value === record?.priorityId)?.label : ''}
                </Tag>
        },
        {
            title: 'RAISED DATE',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string, record: ArticleModel) => (
                <>
                    {moment(record?.createdAt).format("DD/MM/YYYY HH:mm a")}
                </>
            )
        },
        {
            title: 'STATUS',
            key: 'isPublished',
            dataIndex: 'isPublished',
            render: (text: string, record: ArticleModel) => (
                <Tag key={record?.id}
                    className={`app-table-tags ${record?.isPublished ? 'green' : 'active'}`}
                >
                    {record?.isPublished ? 'Published' : 'Draft'}
                </Tag>
            )
        }
    ];

    const customAccessDetails: CustomAccess = useContext(CustomAccessContext)

    if (checkForEditAnddeletePermission(customAccessDetails, 'articleAccess')) {
        columns.push({
            title: null,
            key: 'action',
            render: (text: string, record: ArticleModel) => (
                <div onClick={(event) => event.stopPropagation()}>
                    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}
                        className='manage-groups__details--action'
                        onVisibleChange={(visible) => { setCurrentArticle(visible ? record : new ArticleModel()) }}
                    >
                        <EllipsisOutlined className="table-menu" />
                    </Dropdown>
                </div>
            ),
        })
    }

    const menu = (
        <Menu>
            <PermissionBasedRestriction
                entityName="articleAccess"
                entityPermission="update"
            >
                <Menu.Item key="1" onClick={() => {
                    history.push({
                        pathname: generatePath(appRoutes.ARTICLE_FORM, { id: currentArticle?.id as number }),
                        state: {
                            currentPage: page
                        }
                    }
                    );
                }}>
                    Edit
                </Menu.Item>
            </PermissionBasedRestriction>
            <PermissionBasedRestriction
                entityName="articleAccess"
                entityPermission="delete"
            >
                <Menu.Item key="2" onClick={() => { setShowDelete(true) }}
                >
                    Delete
                </Menu.Item>
            </PermissionBasedRestriction>
        </Menu>
    );

    const handlePageChange = (page: number, pageSize?: number) => {
        history.push({ pathname: appRoutes.ARTICLES, search: `?page=${page}${search !== '' ? `&search=${search}` : ""}`, state: { currentPage: page, search: search } })
    };

    const handleArticleSearch = (searchText: string) => {
        history.push({ pathname: appRoutes.ARTICLES, search: `?page=${1}${search !== '' ? `&search=${searchText}` : ""}`, state: { currentPage: 1, search: searchText } })
    }

    return (
        <div className="app-wrapper articles" ref={rootRef}>
            <RedirectBasedOnThePermission
                entityName={"articleAccess"}
            >
                <AppHeader title={"Content"}
                    handleSearch={handleArticleSearch}
                    isSearchEnabled={true}
                    buttonText={"Content"}
                    buttonHandler={() => {
                        history.push(generatePath(appRoutes.ARTICLE_FORM, { id: 'new' }), { update: false })
                    }}
                    searchValue={search}
                    setSearchValue={setSearch}
                    iconClassName={"icon-articles"}
                    entityName='articleAccess'
                    entityPermission='create'
                />

                <TableComponent columns={columns}
                    data={articleList}
                    loading={tableLoading}
                    handleRowClick={
                        (record: Journey) => {
                            history.push(generatePath(appRoutes.ARTICLE_VIEW, { id: record?.id as number }), { currentPage: page, search: search })
                        }
                    }
                    onPageChange={handlePageChange}
                    currentPage={page}
                    total={totalItems}
                />

                <DeleteConfirmation handleDelete={() => deleteArticle(currentArticle?.id)}
                    setShowConfirmation={setShowDelete}
                    showConfirmation={showDelete}
                    entityName={"Content"}
                    buttonLoading={buttonLoading}
                    key="delete-confirmation"
                    refresh={() => fetchArticles({ search, page: currentPage })}
                />

            </RedirectBasedOnThePermission>

        </div>
    )
}

export default Articles;