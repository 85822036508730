import React, { FC, useEffect, useState } from 'react'
import "./hospitalAdminDashboard.scss"
import AppHeader from '../../../shared/components/AppHeader'
import { useHistory, useLocation } from 'react-router-dom'
import { Journey } from '../../../models/Journey/journey.model'
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import JourneyService from '../../../services/JourneyService/journey.service'
import TableComponent from '../../../shared/components/TableComponent'
import DeleteConfirmation from '../../../shared/components/DeleteConfirmation'
import fetchColumns from './columns'
import fetchMenuItems from './menuItems'
import DashboardService from '../../../services/DashboardService/dashboard.service'

interface HospitalAdminDashboardProps { }

const HospitalAdminDashboard: FC<HospitalAdminDashboardProps> = (props) => {

    const { } = props

    const history = useHistory();
    const location = useLocation<{ currentPage: string }>();
    const urlParams = new URLSearchParams(location.search)
    const search = urlParams.get('search') || '';

    const [searchText, setSearchText] = useState<string>(search);
    const page = parseInt(location?.state?.currentPage) || 1;

    const { deleteJourney, buttonLoading, } = JourneyService();

    const { loading, dashboards, fetchDashboards, dashboardsPagination, } = DashboardService()

    const handleSearch = (searchText: string) => {
        setSearchText(searchText);
        history.push({ ...location, search: `?page=1&search=${searchText}` })
    }

    const handlePageChange = (page: number) => {
        history.push({ pathname: appRoutes.DASHBOARD, search: `?page=${page}${search !== "" ? `&search=${search}` : ''}`, state: { currentPage: page, search: search } });
    };

    const [journey, setJourney] = useState<Journey>(new Journey());
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    const menuItems = fetchMenuItems(history, journey, setShowDeleteConfirmation);

    const columns = fetchColumns(menuItems, setJourney);

    useEffect(() => {
        fetchDashboards({ search: searchText, page: page })
    }, [location])

    return (
        <div className="hospital-admin-dashboard">
            <AppHeader
                title="Assigned Journey"
                isSearchEnabled={true}
                searchValue={searchText}
                setSearchValue={setSearchText}
                handleSearch={handleSearch}
                showGoBack={false}
            />

            <TableComponent
                columns={columns}
                data={dashboards}
                loading={loading}
                onPageChange={handlePageChange}
                currentPage={dashboardsPagination?.page}
                total={dashboardsPagination?.total}
            />

            <DeleteConfirmation
                handleDelete={() => deleteJourney(journey?.id)}
                setShowConfirmation={setShowDeleteConfirmation}
                showConfirmation={showDeleteConfirmation}
                entityName={"Journey"}
                buttonLoading={buttonLoading}
                key="delete-confirmation"
                refresh={()=> fetchDashboards({ search: searchText, page: page })}
            />

        </div>
    )
}

export default HospitalAdminDashboard


