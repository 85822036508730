
import {serializable, alias, object, list, primitive} from 'serializr';

export class Guardian { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('firstname', primitive()))
	firstname?: string;

	@serializable(alias('lastname', primitive()))
	lastname?: string;

	@serializable(alias('display_picture_url', primitive()))
	displayPictureUrl?: object;

	@serializable(alias('class_name', primitive()))
	className?: string;

}