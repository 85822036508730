import React, { useContext, useEffect, useState } from 'react'
import "./staffs.scss"
import { useHistory, useLocation } from "react-router";
import { generatePath } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { Dropdown, Menu, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import TableComponent from "../../shared/components/TableComponent";
import DeleteConfirmation from "../../shared/components/DeleteConfirmation";
import StaffForm from "./StaffForm";
import { MetaModel } from "../../models/meta.model";
import StaffService from "../../services/HospitalService/staff.service";
import { Staff } from "../../models/Hospital/staff.model";
import AppHeader from "../../shared/components/AppHeader";
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { getFullName } from '../../shared/utils/dataFormatConverter';
import { CustomAccessContext } from '../../context/CustomAccessContext';
import { CustomAccess } from '../../models/CustomAccess/customAccess.model';
import { checkForEditAnddeletePermission } from '../../shared/utils/checkForEdit&DeletePermission';
import PermissionBasedRestriction from '../../shared/components/PermissionBasedRestriction';
import RedirectBasedOnThePermission from '../../shared/components/RedirectBasedOnThePermission';

const StaffsList = () => {

    const { tableLoading, fetchStaffs, staffList, currentPage, totalItems } = StaffService();

    const history = useHistory();
    const location = useLocation<{ currentPage: string }>();
    const urlParams = new URLSearchParams(location.search)
    const page = parseInt(urlParams.get('page') || '1');
    const search = urlParams.get('search') || '';

    const { buttonLoading, deleteStaff } = StaffService();

    const [showStaffForm, setShowStaffForm] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [currentStaff, setCurrentStaff] = useState<any>({});

    const [searchText, setSearchText] = useState<string>(search);

    useEffect(() => {
        fetchStaffs({
            search: searchText
        }, page, 10).then();
    }, [location])

    const handlePageChange = (page: number) => {
        history.push({ pathname: history.location.pathname, search: `?page=${page}`, state: { currentPage: page } })
    }
    const handleSearch = (searchText: string) => {
        setSearchText(searchText);
        history.push({ ...location, search: `?page=1&search=${searchText}` })
    }

    let columns: any = [
        {
            title: "NAME",
            key: "name",
            width: 200,
            render: (text: string, record: any) => (
                <span className="text-capitalize"
                    onClick={() =>
                        history.push(
                            generatePath(appRoutes.SHOW_HOSPITAL, { id: record?.id })
                        )
                    }
                >
                    {getFullName(record)}
                </span>
            ),
        },
        {
            title: "EMAIL",
            dataIndex: "email",
            key: "email",
            width: 150,
            ellipsis: true,
        },
        {
            title: "PHONE",
            dataIndex: "phone",
            key: "phone",
            width: 220,
            className: "white-nowrap",
            // render: (text: string, record: Staff) => formatPhoneNumberIntl((record?.isdCode ? record.isdCode : "") + text + (record.extCode ? "#" + record.extCode : ""))
            render: (text: string, record: Staff) => formatPhoneNumberIntl((record?.isdCode ? record.isdCode : "") + text + (!isNaN(parseInt(record.extCode || "")) ? "#" + record.extCode : ""))
        },
        {
            title: "ROLE",
            key: "role",
            width: 100,
            render: (text: string, record: any) => (
                <span className="text-capitalize">{record?.role?.label?.replaceAll("_", " ")}</span>
            ),
        },
        {
            title: "SPECIALIZATION",
            key: "specialization",
            className: "staff-specialization",
            width: 150,
            render: (text: string, record: any) => (
                <span className="text-capitalize">
                    {record?.specialities?.slice(0, 2)?.map((o: MetaModel) => o.label).join(", ")}
                    {(record?.specialities?.length || 0) > 2 && ", +" + ((record?.specialities?.length || 0) - 2)}
                </span>
            ),
        },
        {
            title: "STATUS",
            key: "status",
            dataIndex: "status",
            render: (text: string, record: any) => (
                <Tag
                    key={record?.id}
                    className={`app-table-tags ${record?.status ? "green" : "red"}`}
                >
                    {record?.status ? "Active" : "Inactive"}
                </Tag>
            ),
        }
    ];

    const customAccessDetails: CustomAccess = useContext(CustomAccessContext)

    if (checkForEditAnddeletePermission(customAccessDetails, 'staffAccess')) {
        columns.push({
            title: null,
            key: "action",
            render: (text: string, record: any) => (
                <Dropdown
                    overlay={menu}
                    placement="bottomLeft"
                    trigger={["click"]}
                    className="manage-groups__details--action"
                    onVisibleChange={(visible) => {
                        setCurrentStaff(visible ? record : {});
                    }}
                >
                    <EllipsisOutlined onClick={e => e.stopPropagation()} className="table-menu" />
                </Dropdown>
            ),
        },)
    }

    const menu = (
        <Menu>
            <PermissionBasedRestriction
                entityName="staffAccess"
                entityPermission="update"
            >
                <Menu.Item
                    key="1"
                    onClick={(e) => {
                        e.domEvent.stopPropagation()
                        setShowStaffForm(true);
                    }}
                >
                    Edit
                </Menu.Item>
            </PermissionBasedRestriction>
            <PermissionBasedRestriction
                entityName="staffAccess"
                entityPermission="delete"
            >
                <Menu.Item
                    key="2"
                    onClick={(e) => {
                        e.domEvent.stopPropagation()
                        setShowDeleteConfirmation(true);
                    }}
                >
                    Delete
                </Menu.Item>
            </PermissionBasedRestriction>
        </Menu>
    );

    return (
        <div className="app-wrapper staffs-list">
            <RedirectBasedOnThePermission
                entityName={"staffAccess"}
            >
                <AppHeader iconClassName="icon-members"
                    title="Team"
                    isSearchEnabled={true}
                    buttonText="Staff"
                    buttonHandler={() => {
                        setCurrentStaff({})
                        setShowStaffForm(true)
                    }}
                    searchValue={searchText}
                    setSearchValue={setSearchText}
                    handleSearch={handleSearch}
                    showGoBack={false}
                    entityName='staffAccess'
                    entityPermission='create'
                />

                <TableComponent loading={tableLoading}
                    data={staffList}
                    columns={columns}
                    onPageChange={handlePageChange}
                    currentPage={page}
                    total={totalItems}
                    handleRowClick={
                        (record: Staff) =>
                            history.push(generatePath(appRoutes.SHOW_STAFF, { id: record?.id }))
                    }
                />

                <DeleteConfirmation showConfirmation={showDeleteConfirmation}
                    setShowConfirmation={setShowDeleteConfirmation}
                    entityName={"staff"}
                    handleDelete={() => deleteStaff(currentStaff?.id)}
                    buttonLoading={buttonLoading}
                    refresh={fetchStaffs}
                />

                {
                    showStaffForm && <StaffForm
                        showForm={showStaffForm}
                        setShowForm={setShowStaffForm}
                        staff={currentStaff}
                        refreshList={fetchStaffs}
                    />
                }

            </RedirectBasedOnThePermission >
        </div >
    )
}

export default StaffsList;