import React, { useEffect, useRef, useState } from 'react'
import "./staffDetails.scss"
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import StaffService from "../../../services/HospitalService/staff.service";
import StaffForm from "../StaffForm";
import userPlaceholder from "../../../assets/userPlaceholder.png";
import AssignedPatients from "./AssignedPatients";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";
import { getFullName } from '../../../shared/utils/dataFormatConverter';
import GoBack from '../../../shared/components/GoBack';
import PermissionBasedRestriction from '../../../shared/components/PermissionBasedRestriction';
import RedirectBasedOnThePermission from '../../../shared/components/RedirectBasedOnThePermission';

const StaffDetails = () => {

    const { showStaff, staff, tableLoading } = StaffService();

    const history = useHistory();
    const { id } = useParams();

    const [showOverflow, setShowOverflow] = useState<boolean>(false);
    const [showStaffForm, setShowStaffForm] = useState<boolean>(false);

    const descriptionRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        setTimeout(() => {
            if (descriptionRef.current) {
                descriptionRef.current.innerHTML = staff?.bio?.replace(/\n/gm, '<br />') || "";
                descriptionRef.current.innerHTML = staff?.bio?.replace(/<p>&nbsp;<\/p>/i, ' ') || "";
                descriptionRef.current.innerHTML = staff?.bio?.replace(/<br>/gm, ' ') || "";
            }
        })
    }, [staff?.bio, descriptionRef.current])

    useEffect(() => {
        showStaff(id).then();
    }, [id])

    useEffect(() => {
        setShowOverflow(!!staff?.bio && staff?.bio?.length > 90)
    }, [staff])

    return (
        <div className="app-wrapper staff-details">
            <RedirectBasedOnThePermission
                entityName={"staffAccess"}
            >
                <div className="staff-details__basic-info">
                    {tableLoading ? <SkeletonLoader rows={4} /> :
                        <>
                            <div className="staff-details__navigation">
                                <GoBack />
                                <div className="staff-header__image">
                                    <img src={staff?.displayPictureUrl ?? userPlaceholder} alt='' />
                                </div>
                                <div className="staff-details__name text-capitalize">
                                    {getFullName(staff)}
                                    {staff?.specialities && staff?.specialities?.length > 0 &&
                                        <span className="staff-details__career-info">{staff?.specialities[0].label}</span>
                                    }
                                    {staff?.experience &&
                                        <span className="staff-details__career-info">{staff?.experience} years as specialist</span>
                                    }
                                </div>
                            </div>
                            <div className="staff-details__bio">
                                <span
                                    ref={descriptionRef}
                                    className={showOverflow ? "text-overflow" : ""} />
                                {staff?.bio && staff?.bio?.length > 90 &&
                                    <span className="view-more" onClick={() => setShowOverflow(!showOverflow)}>
                                        {showOverflow ? "View more" : "View less"}
                                    </span>
                                }
                            </div>
                            <div className="staff-details__communication">
                                <div className="staff-details__contact">
                                    <span className="staff-details__contact-icon">
                                        <i className="icon-email" />
                                    </span>
                                    <span className="staff-details__contact-info cursor-pointer"
                                        onClick={(e) => {
                                            window.location.href = `mailto:${staff?.email}`;
                                            e.preventDefault();
                                        }}
                                    >
                                        {staff?.email}
                                    </span>
                                </div>
                                <div className="staff-details__contact">
                                    <span className="staff-details__contact-icon">
                                        <i className="icon-mobile" />
                                    </span>
                                    <span className="staff-details__contact-info">
                                        {staff?.isdCode}-{staff?.phone}
                                    </span>
                                </div>
                                <div className="staff-details__contact">
                                    <span className="staff-details__contact-icon">
                                        <i className="icon-language" />
                                    </span>
                                    <span className="staff-details__contact-info text-capitalize">
                                        {staff?.languagesKnown?.map(o => o.label).join(',')}
                                    </span>
                                </div>
                            </div>
                            <div className="staff-details__website">
                                <a href={staff?.link} target="_blank">
                                    {staff?.link}
                                </a>
                            </div>
                            <PermissionBasedRestriction
                                entityName='staffAccess'
                                entityPermission='update'
                            >
                                <span className="staff-details__edit" onClick={() => setShowStaffForm(true)}>
                                    <i className="icon-edit-1" />
                                </span>
                            </PermissionBasedRestriction>
                        </>
                    }
                </div>

                <div className="staff-details__assigned-patients">
                    <AssignedPatients />
                </div>

                {
                    showStaffForm &&
                    <StaffForm
                        showForm={showStaffForm}
                        setShowForm={setShowStaffForm}
                        staff={staff}
                        refreshList={() => showStaff(staff?.id)}
                    />
                }

            </RedirectBasedOnThePermission>
        </div>
    )
}

export default StaffDetails;