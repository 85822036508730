import React, { FC, useEffect } from "react";
import "./articleDetails.scss";
import { useHistory } from "react-router";
import { Divider } from "antd";
import moment from "moment";
import ArticleComments from "./ArticleComments";
import { generatePath, useLocation, useParams } from "react-router-dom";
import ArticleService from "../../../services/ArticleService/article.service";
import RichTextViewer from "../../../shared/components/RichTextViewer";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import SpinnerLoader from "../../../shared/components/SpinnerLoader";
import bannerPlaceholder from "../../../assets/articlePlaceholder.png";
import GoBack from "../../../shared/components/GoBack";
import PermissionBasedRestriction from "../../../shared/components/PermissionBasedRestriction";
import RedirectBasedOnThePermission from "../../../shared/components/RedirectBasedOnThePermission";

interface ArticleDetailsProps { }

const ArticleDetails: FC<ArticleDetailsProps> = (props) => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const { state } = useLocation<{ currentPage: number, search: string }>();
  const search = (state?.search) || "";
  const page = state ? state.currentPage : 1;
  const { showArticle, article, tableLoading } = ArticleService();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) showArticle(id).then();
  }, [id]);

  return (
    <div className="app-wrapper article-details">
      <RedirectBasedOnThePermission
        entityName={"articleAccess"}
      >
        <GoBack showText route={{ pathname: appRoutes.ARTICLES, search: `?page=${page}&${search !== '' ? `search=${search}` : ''}`, state: { currentPage: page, search: search } }} />
        <PermissionBasedRestriction
          entityName={"articleAccess"}
          entityPermission={"update"}>
          <div className="edit-btn"
            onClick={() => history.push(generatePath(appRoutes.ARTICLE_FORM, { id }), { update: true })}
          >
            <i className="icon-edit"></i>
          </div>
        </PermissionBasedRestriction>
        {tableLoading ? (
          <SpinnerLoader />
        ) : (
          <>
            <div className="article-details__banner-image">
              <img
                src={article?.bannerImageUrl ?? bannerPlaceholder}
                alt="banner-"
              />
            </div>
            <div className="article-details__wrapper">
              <div className="article-details__publish-info">
                {article?.publishedAt && (
                  <>
                    <span className="publish-info__label">Posted on:</span>
                    <span className="publish-info__value">
                      {moment(article?.publishedAt).format("DD/MM/YYYY")}
                    </span>
                    <Divider type={"vertical"} />
                  </>
                )}
                <span className="publish-info__label">Published by:</span>
                <span className="publish-info__value text-capitalize">{article?.authorName}</span>
              </div>
              <div className={"article-details__title text-capitalize"}>{article?.title}</div>
              <div className={"article-details__tags"}>
                {article?.tags?.map((tag, i) => (
                  <div className={"faq-accordion__tag type-1"} key={i}>
                    {tag.label}
                  </div>
                ))}
              </div>

              <div className={"article-details__content__wrapper"}>
                <RichTextViewer content={article?.content} height="100vh" />
              </div>

              <ArticleComments />
            </div>
          </>
        )}
      </RedirectBasedOnThePermission>
    </div>
  );
};

export default ArticleDetails;
