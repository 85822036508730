import React from 'react'
import { getFullName } from "../../../shared/utils/dataFormatConverter";
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Staff } from '../../../models/Hospital/staff.model';

export default [
    {
        title: "NAME",
        key: "name",
        width: 200,
        render: (text: string, record: any) => (
            <span className="text-capitalize" >
                {getFullName(record)}
            </span>
        ),
    },
    {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
        width: 150,
        ellipsis: true,
    },
    {
        title: "PHONE",
        dataIndex: "phone",
        key: "phone",
        width: 220,
        className: "white-nowrap",
        render: (text: string, record: Staff) => formatPhoneNumberIntl((record?.isdCode ? record.isdCode : "") + text + (!isNaN(parseInt(record.extCode || "")) ? "#" + record.extCode : ""))
    },
    {
        title: "ROLE",
        key: "role",
        width: 100,
        render: (text: string, record: any) => (
            <span className="text-capitalize" > {record?.role?.label?.replaceAll("_", " ")} </span>
        ),
    }
];