import React, { createContext, useEffect } from "react";
import CustomAccessService from "../services/CustomAccessService/customAccess.service";
import { useLocation } from "react-router-dom";
import { getUserDataFromLocalStorage } from "../shared/utils/getUserDataFromLocalStorage";
import SkeletonLoader from "../shared/components/SkeletonLoader";

const CustomAccessContext: any = createContext({});

const CustomAccessProvider = (props: any) => {
    const location = useLocation();

    const { customAccess, fetchUserCustomAccesses } = CustomAccessService()
    const { roleId, id: userId } = getUserDataFromLocalStorage()

    useEffect(() => {
        if (!roleId) return
        fetchUserCustomAccesses()
    }, [location, roleId]);

    if (!customAccess && userId) return <SkeletonLoader />

    return <CustomAccessContext.Provider {...props} value={customAccess} />
}

export { CustomAccessProvider, CustomAccessContext };