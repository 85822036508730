import React, { FC, useEffect, useState } from 'react'
import "./roleList.scss"
import { Col, Divider, Row } from 'antd'
import SkeletonLoader from '../../../shared/components/SkeletonLoader'
import { useHistory, useLocation } from 'react-router-dom'
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import PermissionDetails from './PermissionDetails'
import MetaService from '../../../services/MetaService/meta.service'
import AddPermissionForm from './AddPermissionForm'
import { getUserDataFromLocalStorage } from '../../../shared/utils/getUserDataFromLocalStorage'

interface RoleListProps {
    showCustomAccessForm: boolean
    setShowCustomAccessForm: (showForm: boolean) => void;
}

const RoleList: FC<RoleListProps> = (props) => {

    const { showCustomAccessForm, setShowCustomAccessForm,  } = props
    const location = useLocation();
    const history = useHistory();
    const urlParams = new URLSearchParams(location?.search)
    const role = urlParams.get("role") ?? ""
    const searchText = urlParams.get('search') || '';
    const [activeCategoryId, setActiveCategoryId] = useState<string>(role);
    const { fetchEmployeeRoleList, employeeRoleList, loading } = MetaService()
    const { hospitalId } = getUserDataFromLocalStorage()

    useEffect(() => {
        fetchEmployeeRoleList(hospitalId ?? "", searchText)
    }, [searchText])

    useEffect(() => {
        const role = urlParams.get("role")
        if (!employeeRoleList || employeeRoleList.length === 0) return
        if (!role) {
            setActiveCategoryId(employeeRoleList[0].value || "")
        } else {
            const currentRoleIndex = employeeRoleList.findIndex((item) => item.value?.toString() === role.toString())
            if (currentRoleIndex === -1) {
                setActiveCategoryId(employeeRoleList[0].value || "")
            } else {
                setActiveCategoryId(role)
            }
        }
    }, [employeeRoleList, role])

    useEffect(() => {
        if (activeCategoryId === undefined) return
        history.replace({
            pathname: location.pathname,
            hash: 'role',
            search: `&role=${activeCategoryId}`
        });
    }, [activeCategoryId])

    return (
        <div className="role-list">
            <Row>
                <Col span={7}>
                    <div className='role-list-sidebar-container'>
                        {
                            loading
                                ? <SkeletonLoader avatar={false} rows={1} />
                                : employeeRoleList.map((item, index) => <div key={index} className="role-sidebar__title">
                                    <div
                                        className={(item.value?.toString() === activeCategoryId.toString() ? ' active ' : ' ') + " role-title "}
                                        onClick={() => setActiveCategoryId(item.value || "")}
                                    >
                                        <span>{item.label}</span>
                                    </div>
                                    <Divider />
                                </div>
                                )
                        }
                    </div>
                </Col>
                <Col span={16} offset={1}>
                    {
                        employeeRoleList.length > 0 && <PermissionDetails
                            fetchEmployeeRoleList={() => fetchEmployeeRoleList(hospitalId ?? "")}
                            roleId={activeCategoryId}
                        />
                    }
                    <AddPermissionForm
                        showForm={showCustomAccessForm}
                        setShowForm={setShowCustomAccessForm}
                        fetchEmployeeRoleList={() => fetchEmployeeRoleList(hospitalId ?? "")}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default RoleList