import React, { FC, useEffect } from "react";
import "./journeyEventModal.scss";
import { Modal, Tabs, Switch } from "antd";
import JourneyEventDetails from "./JourneyEventDetails";
import JourneyDoctorNotes from "./JourneyDoctorNotes";
import JourneyTemplateAttachments from "../../../../JourneyTemplate/JourneyTemplateDetails/JourneyTemplateSteps/JourneyTemplateAttachments";
import JourneyStepAttachmentService from "../../../../../services/JourneyService/journeyStepAttachment.service";
import { Journey } from "../../../../../models/Journey/journey.model";
import { JourneyTemplateStep } from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import JourneyArticles from "./JourneyArticles";
import JourneyFAQ from "./JourneyFAQ";
import JourneyStepService from "../../../../../services/JourneyService/journeyStep.service";
import { generatePath, useHistory } from "react-router-dom";
import * as appRoutes from "../../../../../routes/routeConstants/appRoutes";
import JourneyPatientCard from "../../../../../shared/components/JourneyPatientCard";
import { Patient } from "../../../../../models/Patient/patient.model";
import PermissionBasedRestriction from "../../../../../shared/components/PermissionBasedRestriction";
const { TabPane } = Tabs;

interface JourneyEventModalProps {
  event: JourneyTemplateStep;
  showDetails: boolean;
  setShowDetails: Function;
  journey?: Journey;
  showJourneyRedirect?: boolean;
  onStatusChange?: () => void;
}

const JourneyEventModal: FC<JourneyEventModalProps> = (props) => {
  const {
    event,
    showJourneyRedirect,
    showDetails,
    setShowDetails,
    journey,
    onStatusChange,
  } = props;

  const history = useHistory();

  const { fetchJourneyStepAttachments, journeyStepAttachmentList } =
    JourneyStepAttachmentService();

  const { updateJourneyStep } = JourneyStepService();

  useEffect(() => {
    if (event?.id) fetchJourneyStepAttachments(event?.id, "attachment");
  }, [event]);

  const handleChange = (isCompleted: boolean) => {
    if (event.journeyCategoryId) {
      const data = Object.assign(new JourneyTemplateStep(), {
        ...event,
        isCompleted,
      });
      updateJourneyStep(event.journeyCategoryId, event.id, data).then(() => {
        onStatusChange && onStatusChange();
      });
    }
  };

  return (
    <div className="journey-event-modal">
      <Modal
        title={null}
        visible={showDetails}
        onCancel={() => setShowDetails(false)}
        footer={null}
        maskClosable = {false}
        destroyOnClose
        className="hospital-form journey-event-modal"
      >
         <div className="journey-event-details__header">
        <div className="journey-event-details__name">
          <span className="journey-event-details__name-text text-capitalize">{event?.title}</span>
          <PermissionBasedRestriction
              entityName={"journeyAccess"}
              entityPermission={"update"}
            >
          <span className="journey-event-details__status text-capitalize">
            Completed
            <Switch
              checked={event?.isCompleted}
              onChange={handleChange}
            />
          </span>
          </PermissionBasedRestriction>
          {showJourneyRedirect && (
            <span
              className={"journey-event-details__redirect"}
              onClick={() =>
                history.push(
                  generatePath(appRoutes.JOURNEY_DETAILS, {
                    id: event?.journeyId,
                  })
                )
              }
            >
              Go to journey
            </span>
          )}
        </div>
        <div className="journey-event-details__patient">
        <JourneyPatientCard hospitalId={journey?.hospital?.id as string} patient={journey?.patient as Patient} allowRedirect/>
        </div>
        </div>
        
        <Tabs type="card" className="journey-event-details__tab">
          <TabPane
            tab={
              <>
                <span className="icon-event-details icon" />
                Event details
              </>
            }
            key="1"
          >
            <JourneyEventDetails event={event} journey={journey} />
          </TabPane>

          <TabPane
            tab={
              <>
                <span className="icon-faq icon" />
                FAQ
              </>
            }
            key="3"
          >
            <JourneyFAQ step={event} />
          </TabPane>
          <TabPane
            tab={
              <>
                <span className="icon-articles icon" />
                Content
              </>
            }
            key="2"
          >
            <JourneyArticles step={event} />
          </TabPane>
          <TabPane
            tab={
              <>
                <span className="icon-doctor-notes icon" />
                Clinical notes
              </>
            }
            key="4"
          >
            <JourneyDoctorNotes event={{ ...event }} onUpdate={() => setShowDetails(false)} />
          </TabPane>
          <TabPane
            tab={
              <>
                <span className="icon-attachments icon" />
                Files
              </>
            }
            key="5"
          >
            <JourneyTemplateAttachments
              step={event}
              attachments={journeyStepAttachmentList}
              refresh={() => { }}
              showDeleteIcon={false}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default JourneyEventModal;
