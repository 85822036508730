
import {serializable, alias, object, list, primitive} from 'serializr';

export class Permissions { 

	@serializable(alias('create', primitive()))
	create?: boolean;

	@serializable(alias('update', primitive()))
	update?: boolean;

	@serializable(alias('read', primitive()))
	read?: boolean;

	@serializable(alias('delete', primitive()))
	delete?: boolean;

}