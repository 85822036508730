import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "./journeyList.scss";
import AppHeader from "../../shared/components/AppHeader";
import TableComponent from "../../shared/components/TableComponent";
import { generatePath, useLocation } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import DeleteConfirmation from "../../shared/components/DeleteConfirmation";
import { useHistory } from "react-router";
import { Dropdown, Menu, Modal, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { Journey } from "../../models/Journey/journey.model";
import JourneyService from "../../services/JourneyService/journey.service";
import { getAgeText } from "../../shared/utils/dateHelpers";
import { getFullName } from "../../shared/utils/dataFormatConverter";
import JourneyStatusForm from "./JourneyDetails/JourneyDetailHeader/JourneyStatusForm";
import { AuthReducerProps } from "../../store/reducers/authReducer";
import AuthContainer from "../../store/container/AuthContainer";
import PermissionBasedRestriction from "../../shared/components/PermissionBasedRestriction";
import { checkForEditAnddeletePermission } from "../../shared/utils/checkForEdit&DeletePermission";
import { CustomAccess } from "../../models/CustomAccess/customAccess.model";
import { CustomAccessContext } from "../../context/CustomAccessContext";
import RedirectBasedOnThePermission from "../../shared/components/RedirectBasedOnThePermission";

interface JourneyListProps extends AuthReducerProps { }

const JourneyList: FC<JourneyListProps> = (props) => {
  const { authenticated } = props;
  const rootRef = useRef<null | HTMLDivElement>(null)
  const history = useHistory();
  const location = useLocation<{ currentPage: string, search: string }>();
  const page = parseInt(location?.state?.currentPage) || 1;
  const search = (location?.state?.search) || "";
  const {
    journeyList,
    fetchJourneys,
    tableLoading,
    totalItems,
    deleteJourney,
    buttonLoading,
  } = JourneyService();

  const [journey, setJourney] = useState<Journey>(new Journey());
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>(search);


  useEffect(() => {
    //  work around to fix requireAuth's redirection bug
    if (authenticated) fetchJourneys({ search: searchText }, page, 10);
    rootRef!.current!.scrollIntoView({
      behavior: 'smooth'
    });
  }, [location]);

  const handleJourneySearch = (searchText: string) => {
    history.push({ pathname: appRoutes.JOURNEYS, search: `?page=${1}&search=${searchText}`, state: { currentPage: 1, search: searchText } });
  };

  const handleToggleStatusModal = () => setShowStatusModal(!showStatusModal);

  let columns: any = [
    {
      title: "PATIENT NAME",
      dataIndex: "patientName",
      key: "patientName",
      width: 170,
      ellipsis: true,
      render: (text: string, record: Journey) => (
        <p
          className="table-link text-capitalize"
          onClick={(e) => {
            e.stopPropagation();
            if (!record?.patient?.id || !record?.hospital?.id) return;

            history.push(
              generatePath(appRoutes.SHOW_PATIENT, {
                id: record?.patient?.id,
                hospitalId: record?.hospital?.id,
              })
            );
          }}
        >
          {getFullName(record?.patient)}
        </p>
      ),
    },
    {
      title: "CAREGIVER NAME",
      dataIndex: "guardian",
      width: 120,
      ellipsis: true,
      render: (text: string, record: Journey) => (
        <p
          className="table-link text-capitalize"
          onClick={(e) => {
            e.stopPropagation();
            if (!record?.caretaker?.id || !record?.hospital?.id) return;

            history.push(
              generatePath(appRoutes.SHOW_CARETAKER, {
                id: record?.caretaker?.id,
                hospitalId: record?.hospital?.id,
              })
            );
          }}
        >
          {getFullName(record?.caretaker)}
        </p>
      ),
    },
    {
      title: "SEX",
      dataIndex: "gender",
      key: "gender",
      width: 70,
      render: (text: string, record: Journey) => (
        <span className="text-capitalize">{record?.patient?.gender}</span>
      ),
    },
    {
      title: "AGE",
      dataIndex: "age",
      key: "age",
      width: 80,
      render: (text: string, record: Journey) => (
        <>{record?.patient?.dob ? getAgeText(record?.patient?.dob) : record?.patient?.age ? `${record?.patient?.age} Y` : ""}</>
      ),
    },
    {
      title: "HOSPITAL NAME",
      dataIndex: "hospitalName",
      key: "hospitalName",
      width: 170,
      ellipsis: true,
      render: (text: string, record: Journey) => <span className="text-capitalize">{record?.hospital?.name}</span>,

    },
    {
      title: "JOURNEY STATUS",
      key: "status",
      width: 100,
      dataIndex: "status",
      render: (text: string, record: Journey) => (
        <Tag key={record?.id} className={`app-table-tags ${record?.status}`}>
          {record?.status}
        </Tag>
      ),
    },
    {
      title: "JOURNEY",
      key: "name",
      width: 170,
      ellipsis: true,
      dataIndex: "name",
    }
  ];

  const customAccessDetails: CustomAccess = useContext(CustomAccessContext)

  if (checkForEditAnddeletePermission(customAccessDetails, 'journeyAccess')) {
    columns.push({
      title: '',
      key: "action",
      width: 50,
      render: (text: string, record: Journey) => (
        <div onClick={(event) => event.stopPropagation()}>
          <Dropdown
            overlay={menu}
            placement="bottomLeft"
            trigger={["click"]}
            className="manage-groups__details--action"
            onVisibleChange={(visible) => {
              setJourney(visible ? record : new Journey());
            }}
          >
            <EllipsisOutlined className="table-menu" />
          </Dropdown>
        </div>
      ),
    })
  }

  const menu = (
    <Menu>
      <PermissionBasedRestriction
        entityName="journeyAccess"
        entityPermission="update"
      >
        <Menu.Item
          key="1"
          onClick={() => {
            history.push(
              generatePath(appRoutes.JOURNEY_FORM, { id: journey?.id })
            );
          }}
        >
          Edit
        </Menu.Item>
      </PermissionBasedRestriction>
      <PermissionBasedRestriction
        entityName="journeyAccess"
        entityPermission="delete"
      >
        <Menu.Item
          key="2"
          onClick={() => {
            setShowDeleteConfirmation(true);
          }}
        >
          Delete
        </Menu.Item>
      </PermissionBasedRestriction>
      <PermissionBasedRestriction
        entityName="journeyAccess"
        entityPermission="update"
      >
        <Menu.Item
          key="3"
          onClick={handleToggleStatusModal}
        >
          Change Status
        </Menu.Item>
      </PermissionBasedRestriction>
    </Menu>
  );

  const handlePageChange = (page: number, pageSize?: number) => {
    history.push({ pathname: appRoutes.JOURNEYS, search: `?page=${page}${search !== "" ? `&search=${search}` : ''}`, state: { currentPage: page, search: search } });

  };

  return (

    <div className="app-wrapper journey-list" ref={rootRef}>
      <RedirectBasedOnThePermission
        entityName={"journeyAccess"}
      >

        <AppHeader
          iconClassName="icon-journey"
          title="Journey"
          isSearchEnabled={true}
          buttonText="Journey"
          buttonActionText="Create"
          buttonHandler={() => {
            history.push(generatePath(appRoutes.JOURNEY_FORM, { id: "new" }));
          }}
          searchValue={searchText}
          setSearchValue={setSearchText}
          handleSearch={handleJourneySearch}
          showGoBack={false}
          entityName="journeyAccess"
          entityPermission="create"
        />

        <TableComponent
          columns={columns}
          data={journeyList}
          loading={tableLoading}
          handleRowClick={(record: Journey) =>
            history.push(
              generatePath(appRoutes.JOURNEY_DETAILS, { id: record?.id }),
              { currentPage: page, search: search }
            )
          }
          onPageChange={handlePageChange}
          currentPage={page}
          total={totalItems}
        />

        <DeleteConfirmation
          handleDelete={() => deleteJourney(journey?.id)}
          setShowConfirmation={setShowDeleteConfirmation}
          showConfirmation={showDeleteConfirmation}
          entityName={"Journey"}
          buttonLoading={buttonLoading}
          key="delete-confirmation"
          refresh={fetchJourneys}
        />

        <Modal
          title={null}
          footer={null}
          width="25%"
          visible={showStatusModal}
          maskClosable={false}
          centered
          destroyOnClose
          onCancel={handleToggleStatusModal}
          className="journey-status-modal"
        >
          <JourneyStatusForm
            onSuccess={(res) => {
              setShowStatusModal(false);
              res && fetchJourneys({ search: searchText })
            }}
            journey={journey} />
        </Modal>
      </RedirectBasedOnThePermission>
    </div>
  );
};

export default AuthContainer(JourneyList);
