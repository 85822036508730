import React, { FC, useEffect, useState } from "react";
import "./faq.scss";
import AppHeader from "../../shared/components/AppHeader";
import FaqSidebar from "./FaqSidebar";
import { Col, Row } from "antd";
import FaqAccordions from "./FaqAccordions";
import { useHistory } from "react-router";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { generatePath, useLocation } from "react-router-dom";
import FaqService from "../../services/FaqService/faq.service";
import RedirectBasedOnThePermission from "../../shared/components/RedirectBasedOnThePermission";

interface FaqProps { }

const Faq: FC<FaqProps> = (props) => {
  const { } = props;

  const history = useHistory();

  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search)
  const [activeCategoryId, setActiveCategoryId] = useState<string>(location.hash.substring(1));
  const [searchText, setSearchText] = useState(urlParams.get('search') || "");
  const {
    faqList,
    faqLoading,
    faqTotalItems,
    faqCurrentPage,
    fetchFaqs,
    fetchSearchFaqs,
    deleteFaq,
    fetchCategories,
    categoryList,
    buttonLoading,
  } = FaqService();

  useEffect(() => {
    fetchCategories().then();
  }, []);


  useEffect(() => {
    activeCategoryId && (searchText ? fetchSearchFaqs(activeCategoryId, { search: searchText }, true) : fetchFaqs(activeCategoryId, { search: searchText }, true))
    history.replace({
      pathname: appRoutes.FAQ,
      hash: activeCategoryId?.toString(),
      search: `&search=${searchText}`
    });
  }, [activeCategoryId]);

  const handleSearch = (search: string) => {
    history.replace({
      pathname: appRoutes.FAQ,
      hash: activeCategoryId?.toString(),
      search: `&search=${search}`
    });
    (search.trim() == "") ? fetchFaqs(activeCategoryId, {}, true) : fetchSearchFaqs(activeCategoryId, { search }, true);
    (search.trim() == "") && fetchCategories().then();
    if (categoryList.length > 0 && categoryList[0].value) setActiveCategoryId(String(categoryList[0].value));
  };

  return (
    <div className="app-wrapper faq">
      <RedirectBasedOnThePermission
        entityName={"faqAccess"}
      >
        <AppHeader
          iconClassName="icon-faq"
          title="FAQ"
          isSearchEnabled={true}
          buttonText="FAQ"
          buttonHandler={() => {
            activeCategoryId &&
              history.push(
                generatePath(appRoutes.FAQ_FORM, {
                  categoryId: activeCategoryId,
                  id: "new",
                })
              );
          }}
          searchValue={searchText}
          setSearchValue={setSearchText}
          handleSearch={handleSearch}
          showGoBack={false}
          entityName="faqAccess"
          entityPermission="create"
        />
        <div className="faq-wrapper">
          <Row>
            <Col span={7}>
              <FaqSidebar
                setActiveCategoryId={setActiveCategoryId}
                activeCategoryId={activeCategoryId}
                categoryList={categoryList}
                fetchCategories={fetchCategories}
              />
            </Col>
            <Col span={16} offset={1}>
              <FaqAccordions
                {...{
                  activeCategoryId,
                  fetchFaqs,
                  faqList,
                  searchText,
                  faqLoading,
                  faqTotalItems,
                  faqCurrentPage,
                  deleteFaq,
                  buttonLoading,
                }}
              />
            </Col>
          </Row>
        </div>
      </RedirectBasedOnThePermission>
    </div>
  );
};

export default Faq;
