import React, { FC } from 'react'
import "./tableComponent.scss"
import { Table } from "antd";

interface TableComponentProps {
    columns: Array<object>;
    data: Array<object>;
    loading: boolean;
    handleRowClick?: Function;
    onPageChange?: (page: number, pageSize?: number) => void;
    currentPage?: number;
    total?: number;
    isNoPagination?: boolean
}

const TableComponent: FC<TableComponentProps> = (props) => {
    const { columns, data, loading, handleRowClick, onPageChange, currentPage, total, isNoPagination } = props;

    return (
        <div className="table-component">
            <Table columns={columns}
                dataSource={data}
                className="app-table"
                loading={loading}
                rowKey='id'
                pagination={isNoPagination ? false :
                    {
                        onChange: onPageChange,
                        current: currentPage,
                        total: total,
                        // hideOnSinglePage: true,
                    }
                }
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            if (handleRowClick) handleRowClick(record);
                        }
                    };
                }}
            />
        </div>
    )
}

export default TableComponent;