import React, { FC, useContext } from 'react'
import "./redirectBasedOnThePermission.scss"
import { CustomAccess } from '../../../models/CustomAccess/customAccess.model'
import { checkPermission } from '../../utils/checkPermission'
import { Redirect, generatePath } from 'react-router-dom'
import { CustomAccessContext } from '../../../context/CustomAccessContext'
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { getUserDataFromLocalStorage } from '../../utils/getUserDataFromLocalStorage'

interface RedirectBasedOnThePermissionProps {
    entityName: keyof (CustomAccess)
}

interface menuListProps {
    permissionName: keyof (CustomAccess),
    route: string
}

const RedirectBasedOnThePermission: FC<RedirectBasedOnThePermissionProps> = (props) => {

    const customAccessDetails: CustomAccess = useContext(CustomAccessContext)

    const { hospitalId } = getUserDataFromLocalStorage()

    if (!hospitalId) return <Redirect to={AppRoutes.LOGIN} />

    const menuList: menuListProps[] = [
        {
            permissionName: "patientAccess",
            route: AppRoutes.PATIENTS
        },
        {
            permissionName: "caregiverAccess",
            route: AppRoutes.PATIENTS
        },
        {
            permissionName: "staffAccess",
            route: AppRoutes.STAFFS
        },
        {
            permissionName: "journeyAccess",
            route: AppRoutes.JOURNEYS
        },
        {
            permissionName: "articleAccess",
            route: AppRoutes.ARTICLES
        },
        {
            permissionName: "faqAccess",
            route: AppRoutes.FAQ
        },
        {
            permissionName: "journeyTemplateAccess",
            route: AppRoutes.JOURNEY_TEMPLATES
        },
        {
            permissionName: "hospitalAccess",
            route: generatePath(AppRoutes.SHOW_HOSPITAL, { id: hospitalId })
        },
    ]

    let selecterRouteObject: menuListProps = {
        permissionName: "id",
        route: AppRoutes.AUTH
    };

    for (let i = 0; i < menuList.length; i++) {
        const hasAccess = checkPermission(customAccessDetails, menuList[i].permissionName, 'read')
        if (hasAccess) {
            selecterRouteObject = menuList[i]
            break
        }
    }

    const hasAccess = checkPermission(customAccessDetails, props.entityName, 'read')

    if (!hasAccess) return <Redirect to={selecterRouteObject.route} />

    return (
        <>{props.children}</>
    )

}

export default RedirectBasedOnThePermission