import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { CustomAccess } from '../../models/CustomAccess/customAccess.model';
import { PaginationModel } from './../../models/pagination.model';

const CustomAccessService = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const [buttonLoading, setButtonLoading] = useState<boolean>(false)

    const [customAccesses, setCustomAccesses] = useState<CustomAccess[]>([])

    const [customAccessesPagination, setCustomAccessesPagination] = useState<PaginationModel | null>()

    const [customAccess, setCustomAccess] = useState<CustomAccess | null>()

    const fetchCustomAccesses = async (params?: {
        search?: string,
        page?: number
    }) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(ApiRoutes.CUSTOM_ACCESS, { params })
            const data = deserialize(CustomAccess, response.data["CUSTOM_ACCESS"]) as CustomAccess[]
            const pagination = deserialize(PaginationModel, response.data["meta"])
            setCustomAccessesPagination(pagination)
            setCustomAccesses(data)
        } catch (error) {
            setCustomAccesses([])
        } finally {
            setLoading(false)
        }
    }

    const createCustomAccess = async (customAccess: CustomAccess, roleId: string) => {
        try {
            const userDetails = JSON.parse(localStorage.getItem("user") ?? "")
            setButtonLoading(true)
            const payload = {
                ...serialize(CustomAccess, customAccess),
                employee_role_name: roleId,
                employee_role_id: roleId,
                hospital_id: userDetails.hospitalId
            }
            const response = await axiosInstance.post(ApiRoutes.CUSTOM_ACCESS, payload)
            const data = deserialize(CustomAccess, response.data) as CustomAccess;
            setCustomAccess(data)
        } catch (error) {
            setCustomAccess(null)
        } finally {
            setButtonLoading(false)
        }
    }

    const updateCustomAccess = async (customAccess: CustomAccess) => {
        try {
            setButtonLoading(true)
            const payload = serialize(CustomAccess, customAccess)
            const API_URL = generatePath(ApiRoutes.CUSTOM_ACCESS_SPECIFIC, { id: String(customAccess.id) })
            const response = await axiosInstance.put(API_URL, payload)
            const data = deserialize(CustomAccess, response.data) as CustomAccess;
            setCustomAccess(data)
        } catch (error) {
            setCustomAccess(null)
        } finally {
            setButtonLoading(false)
        }
    }

    const deleteCustomAccess = async (customAccessId: string) => {
        try {
            setButtonLoading(true)
            const API_URL = generatePath(ApiRoutes.CUSTOM_ACCESS_SPECIFIC, { customAccessId })
            await axiosInstance.delete(API_URL)
            return true
        } catch (error) {
            return false
        } finally {
            setButtonLoading(false)
        }
    }

    const showCustomAccess = async (roleId: string) => {
        try {
            setLoading(true)
            const API_URL = generatePath(ApiRoutes.USER_ACCESS_PERMISSIONS, { id: roleId })
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(CustomAccess, response.data) as CustomAccess;
            setCustomAccess(data)
        } catch (error) {
            setCustomAccess(undefined)
        } finally {
            setLoading(false)
        }
    }

    const fetchUserCustomAccesses =async () => {
        try {
            setLoading(true)
            const API_URL = generatePath(ApiRoutes.USER_PERMISSIONS)
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(CustomAccess, response.data) as CustomAccess;
            setCustomAccess(data)
        } catch (error) {
            setCustomAccess(undefined)
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        customAccess,
        customAccesses,
        buttonLoading,
        showCustomAccess,
        createCustomAccess,
        deleteCustomAccess,
        fetchCustomAccesses,
        updateCustomAccess,
        customAccessesPagination,
        fetchUserCustomAccesses
    }
}

export default CustomAccessService