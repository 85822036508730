import React, { FC, useState } from 'react'
import "./userAndRoles.scss"
import AppHeader from '../../shared/components/AppHeader'
import { Tabs } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import UserList from './UserList';
import RoleList from './RoleList';

const { TabPane } = Tabs;

interface UserAndRolesProps { }

const UserAndRoles: FC<UserAndRolesProps> = (props) => {

    const { } = props
    const location = useLocation<any>();
    const urlParams = new URLSearchParams(location.search)
    const history = useHistory()

    const key = location?.state?.selectedKey || location.hash.substring(1) || "users";
    const [selectedKey, setSelectedKey] = useState<string>(key);
    const search = urlParams.get('search') || '';
    const role = urlParams.get('role') || '';
    const [searchText, setSearchText] = useState<string>(search);
    const [showStaffForm, setShowStaffForm] = useState<boolean>(false);
    const [showCustomAccessForm, setShowCustomAccessForm] = useState<boolean>(false);

    const handleTabChange = (key: string) => {
        setSelectedKey(key);
        setSearchText('');
        history.push({
            pathname: history.location.pathname,
            hash: key,
            search: "?page=1",
            state: { currentPage: 1 }
        })
    }

    const handleSearch = (searchText: string) => {
        history.push({
            pathname: history.location.pathname,
            hash: key,
            search:`?page=1&search=${searchText}&role=${role}`,
            state: { currentPage: 1 }
        });
    }

    const isUserPage = selectedKey === 'users'

    return (
        <div className="app-wrapper user-and-roles">
            <AppHeader iconClassName="icon-management"
                title="User & Roles"
                isSearchEnabled={true}
                buttonText={isUserPage ? "User" : "Role"}
                buttonActionText="+ Add"
                buttonHandler={() => {
                    if (isUserPage) {
                        setShowStaffForm(true)
                    } else {
                        setShowCustomAccessForm(true)
                    }
                }}
                searchValue={searchText}
                setSearchValue={setSearchText}
                handleSearch={handleSearch}
                showGoBack={false}
            />

            <Tabs activeKey={selectedKey} onChange={handleTabChange} destroyInactiveTabPane >
                <TabPane tab="Users" key="users">
                    <UserList
                        showStaffForm={showStaffForm}
                        setShowForm={setShowStaffForm}
                    />
                </TabPane>
                <TabPane tab="Role" key="role">
                    <RoleList
                        setShowCustomAccessForm={setShowCustomAccessForm}
                        showCustomAccessForm={showCustomAccessForm}
                    />
                </TabPane>
            </Tabs>

        </div>
    )
}

export default UserAndRoles