import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "./caretakerList.scss";
import { useHistory } from "react-router";
import { generatePath, useParams } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { Dropdown, Menu, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import CareTakerForm from "./CaretakerForm";
import { Caretaker, Patient } from "../../../models/Patient/patient.model";
import CaretakerService from "../../../services/CaretakerService/caretaker.service";
import DeleteConfirmation from "../../../shared/components/DeleteConfirmation";
import TableComponent from "../../../shared/components/TableComponent";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { getFullName } from "../../../shared/utils/dataFormatConverter";
import { CustomAccessContext } from "../../../context/CustomAccessContext";
import { CustomAccess } from "../../../models/CustomAccess/customAccess.model";
import { checkForEditAnddeletePermission } from "../../../shared/utils/checkForEdit&DeletePermission";
import PermissionBasedRestriction from "../../../shared/components/PermissionBasedRestriction";

interface CaretakerListProps {
  fetchCaretakers: Function;
  tableLoading: boolean;
  caretakerList: Caretaker[];
  currentPage?: number;
  totalItems?: number;
  handlePageChange?: (page: number, pageSize?: number) => void;
  hospitalId: string | number;
}

const CaretakerList: FC<CaretakerListProps> = (props) => {
  const {
    tableLoading,
    hospitalId: id,
    fetchCaretakers,
    caretakerList,
    currentPage,
    totalItems,
    handlePageChange,
  } = props;

  const rootRef = useRef<null | HTMLDivElement>(null);

  const history: any = useHistory();

  const { deleteCaretaker, caretakerButtonLoading } = CaretakerService();

  const [showCaretakerForm, setShowCaretakerForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [currentCaretaker, setCurrentCaretaker] = useState<any>({});

  useEffect(() => {
    fetchCaretakers(id);
    if (history.location.state) {
      const { showForm } = history.location.state;
      if (showForm) {
        setShowCaretakerForm(true);
      }
    }
    rootRef!.current!.scrollIntoView({
      behavior: 'smooth'
    });
  }, []);
  let columns: any = [
    {
      title: "NAME",
      key: "firstname",
      width: 100,
      ellipsis: true,
      render: (text: string, record: Caretaker) => (
        <span className="text-capitalize">
          {getFullName(record)}
        </span>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: 150,
      ellipsis: true,
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
      className: "white-nowrap",
      width: 150,
      ellipsis: true,
      render: (text: string, record: Caretaker) => formatPhoneNumberIntl((record?.isdCode ? record.isdCode : "") + text)
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      width: 70,
      render: (text: string, record: Caretaker) => (
        <Tag
          key={record?.id}
          className={`app-table-tags ${record?.isActive ? "green" : "red"}`}
        >
          {record?.isActive ? "Active" : "Inactive"}
        </Tag>
      ),
    }
  ];

  const customAccessDetails: CustomAccess = useContext(CustomAccessContext)

  if (checkForEditAnddeletePermission(customAccessDetails, 'caregiverAccess')) {
    columns.push({
      title: null,
      key: "action",
      width: 50,
      render: (text: string, record: any) => (
        <Dropdown
          overlay={menu}
          placement="bottomLeft"
          trigger={["click"]}
          className="manage-groups__details--action"
          onVisibleChange={(visible) =>
            setCurrentCaretaker(visible ? record : {})
          }
        >
          <EllipsisOutlined
            className="table-menu"
            onClick={(e) => e.stopPropagation()}
          />
        </Dropdown>
      ),
    })
  }

  const menu = (
    <Menu>
      <PermissionBasedRestriction
        entityName="caregiverAccess"
        entityPermission="update"
      >
        <Menu.Item
          key="1"
          onClick={(e) => {
            e.domEvent.stopPropagation();
            setShowCaretakerForm(true);
          }}
        >
          Edit
        </Menu.Item>
      </PermissionBasedRestriction>
      <PermissionBasedRestriction
        entityName="caregiverAccess"
        entityPermission="delete"
      >
        <Menu.Item
          key="2"
          onClick={(e) => {
            e.domEvent.stopPropagation();
            setShowDeleteConfirmation(true);
          }}
        >
          Delete
        </Menu.Item>
      </PermissionBasedRestriction>
    </Menu>
  );

  const deleteHandler = async () => {
    if (!id || !currentCaretaker.id) return;
    await deleteCaretaker(id, currentCaretaker.id);
  };

  return (
    <div className="staffs patients-list" ref={rootRef}>
      <TableComponent
        loading={tableLoading}
        data={caretakerList}
        columns={columns}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        total={totalItems}
        handleRowClick={(record: Patient) =>
          record?.id &&
          history.push({
            pathname: generatePath(appRoutes.SHOW_CARETAKER, {
              hospitalId: id,
              id: record?.id,
            }),
            state: {
              currentPage: currentPage
            },
            hash: "caretaker",
          }
          )
        }
      />

      <CareTakerForm
        showForm={showCaretakerForm}
        setShowForm={setShowCaretakerForm}
        caretaker={currentCaretaker}
        hospitalId={id}
        refreshList={fetchCaretakers}
      />

      <DeleteConfirmation
        showConfirmation={showDeleteConfirmation}
        setShowConfirmation={setShowDeleteConfirmation}
        entityName={"caregiver"}
        handleDelete={deleteHandler}
        buttonLoading={caretakerButtonLoading}
        refresh={fetchCaretakers}
      />
    </div>
  );
};

export default CaretakerList;
