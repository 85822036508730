import React, { FC } from 'react'
import "./addPermissionForm.scss"
import { Col, Modal, Row } from 'antd'
import { Form, Formik } from 'formik';
import defaultPermissions from '../PermissionDetails/defaultPermissions';
import InputField from '../../../../shared/components/InputField';
import PermissionRowDetails from '../PermissionDetails/PermissionRowDetails';
import ButtonComponent from '../../../../shared/components/ButtonComponent';
import { permissionFormValidationSchema } from '../PermissionDetails/permissionFormValidation';
import MetaService from '../../../../services/MetaService/meta.service';
import CustomAccessService from '../../../../services/CustomAccessService/customAccess.service';
import { useHistory, useLocation } from 'react-router-dom';

interface AddPermissionFormProps {
    showForm: boolean
    setShowForm: (showForm: boolean) => void;
    fetchEmployeeRoleList: () => {},
}

const AddPermissionForm: FC<AddPermissionFormProps> = (props) => {

    const { showForm, setShowForm, fetchEmployeeRoleList } = props
    const removeItemsList = ['id', 'name']
    const permissionList = Object.keys(defaultPermissions).filter((item) => !removeItemsList.includes(item))
    const history = useHistory();
    const location = useLocation<{ currentPage: string }>();
    const { createEmployeeRole } = MetaService()
    const { createCustomAccess } = CustomAccessService()

    return (
        <Modal
            title={null}
            visible={showForm}
            onCancel={() => {
                setShowForm(false);
            }}
            destroyOnClose
            footer={null}
            maskClosable={false}
            className="add-permission-form checkbox-as-radio"
        >
            <Formik
                enableReinitialize
                initialValues={{
                    ...defaultPermissions,
                }}
                validationSchema={permissionFormValidationSchema}
                onSubmit={async (values) => {
                    const roleDetails = await createEmployeeRole(values.name)
                    await createCustomAccess(values, roleDetails.value ?? "")
                    await fetchEmployeeRoleList()
                    setShowForm(false);
                    history.replace({
                        pathname: location.pathname,
                        hash: 'role',
                        search: `&role=${roleDetails.value}`
                    });
                }}
            >
                {({ resetForm, isSubmitting }) => (
                    <Form>
                        <div className="permission-form-header">
                            Add Role
                        </div>
                        <div className='top-spacing-30'>
                            <InputField
                                type="text"
                                name="name"
                                placeholder="Enter Role Name"
                            />
                        </div>
                        <Row className='top-spacing-30'>
                            <Col span={4}>
                                <span>Feature</span>
                            </Col>
                            <Col span={4} offset={1}>
                                <div className='text-center'> Create </div>
                            </Col>
                            <Col span={5}>
                                <div className='text-center'>  Read</div>
                            </Col>
                            <Col span={5}>
                                <div className='text-center'>Update </div>
                            </Col>
                            <Col span={5}>
                                <div className='text-center'> Delete </div>
                            </Col>
                        </Row>
                        {
                            permissionList.map((item, index) =>
                                <PermissionRowDetails key={index} fieldName={item} />
                            )
                        }
                        <div className="action-buttons">
                            <ButtonComponent
                                className="hospital-form__action"
                                type="text"
                                text="Cancel"
                                onClick={() => {
                                    resetForm()
                                    setShowForm(false);
                                }}
                            />
                            <ButtonComponent
                                htmlType="submit"
                                loading={isSubmitting}
                                className="hospital-form__action"
                                type="primary"
                                text="Save"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>

    )
}

export default AddPermissionForm