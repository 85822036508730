import React, { FC, useContext } from 'react'
import "./permissionBasedRestriction.scss"
import { CustomAccess } from '../../../models/CustomAccess/customAccess.model'
import { CustomAccessContext } from '../../../context/CustomAccessContext'
import { Permissions } from '../../../models/Permissions/permissions.model'
import { checkPermission } from '../../utils/checkPermission'

interface PermissionBasedRestrictionProps {
    entityName: keyof CustomAccess
    entityPermission: keyof Permissions
}

const PermissionBasedRestriction: FC<PermissionBasedRestrictionProps> = (props) => {

    const { entityName, entityPermission } = props

    const customAccessDetails: CustomAccess = useContext(CustomAccessContext)

    const hasAccess = checkPermission(customAccessDetails, entityName, entityPermission);

    return (
        <>
            {
                hasAccess ? props.children : null
            }
        </>
    )
}

export default PermissionBasedRestriction