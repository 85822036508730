import { CustomAccess } from "../../models/CustomAccess/customAccess.model"
import { checkPermission } from "./checkPermission"

export const checkForEditAnddeletePermission = (customAccessDetails: CustomAccess, entityName: keyof CustomAccess) => {

    const hasEditPermission = checkPermission(customAccessDetails, entityName, 'update')
    const hasDeletePermission = checkPermission(customAccessDetails, entityName, 'delete')

    return hasEditPermission || hasDeletePermission

}

