export default (permissionName: string) => {
    let readableName = '';
    switch (permissionName) {
        case 'hospitalAccess':
            readableName = 'Hospital';
            break;

        case 'hosDepartmentAccess':
            readableName = 'HOS Department';
            break;

        case 'staffAccess':
            readableName = 'Staff';
            break;

        case 'caregiverAccess':
            readableName = 'Caregiver';
            break;

        case 'patientAccess':
            readableName = 'Patient';
            break;

        case 'journeyAccess':
            readableName = 'Journey';
            break;

        case 'journeyTemplateAccess':
            readableName = 'Journey Template';
            break;

        case 'articleAccess':
            readableName = 'Article';
            break;

        case 'faqAccess':
            readableName = 'FAQ';
            break;

        case 'tagsAccess':
            readableName = 'Tags';
            break;

        case 'chatAccess':
            readableName = 'Chat';
            break;
    }

    return readableName;
}