import { Menu } from "antd"
import React from "react"
import { generatePath } from "react-router-dom"
import { Journey } from "../../../models/Journey/journey.model"
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { History } from "history";

const fetchMenuItems = (history: History, journey: Journey, setShowDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>) => {
    return <Menu>
        <Menu.Item
            key="1"
            onClick={() => {
                history.push(
                    generatePath(appRoutes.JOURNEY_FORM, { id: journey?.id })
                )
            }}
        >
            Edit
        </Menu.Item>
        <Menu.Item
            key="2"
            onClick={() => {
                setShowDeleteConfirmation(true)
            }}
        >
            Delete
        </Menu.Item>
    </Menu>
}

export default fetchMenuItems