
import {Guardian} from '../Guardian/guardian.model';

import {serializable, alias, object, list, primitive} from 'serializr';
import { Patient } from '../Patient/patient.model';

export class UpcomingEvent { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('guardian', object(Guardian)))
	guardian?: Guardian;

	@serializable(alias('patient', object(Patient)))
	patient?: Patient;

	@serializable(alias('journey_template_id', primitive()))
	journeyTemplateId?: string;

	@serializable(alias('surgery_date', primitive()))
	surgeryDate?: object;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

}