import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { PaginationModel } from '../../models/pagination.model';
import { JourneyDashboard } from '../../models/JourneyDashboard/journeyDashboard.model';
import { UpcomingEvent } from '../../models/UpcomingEvent/upcomingEvent.model';
import { PatientRequest } from '../../models/PatientRequest/patientRequest.model';

const DashboardService = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const [dashboards, setDashboards] = useState<JourneyDashboard[]>([])

    const [dashboardsPagination, setDashboardsPagination] = useState<PaginationModel | null>()

    const [upcomingEvent, setUpcomingEvent] = useState<UpcomingEvent[]>([])

    const [patientRequest, setPatientRequest] = useState<PatientRequest[]>([])

    const [patientRequestLoading, setPatientRequestLoading] = useState<boolean>(false)

    const fetchDashboards = async (params?: {
        search?: string,
        page?: number
    }) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARDS, { params })
            const data = deserialize(JourneyDashboard, response.data["journeys"]["results"]) as JourneyDashboard[]
            const paginationData = deserialize(PaginationModel, response.data["journeys"]);
            setDashboardsPagination(paginationData)
            setDashboards(data)
        } catch (error) {
            setDashboards([])
        } finally {
            setLoading(false)
        }
    }

    const fetchUpcomingEvents = async (params?: {
        search: string
    }) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARDS_UPCOMING_EVENTS, { params })
            const data = deserialize(UpcomingEvent, response.data["events"]) as UpcomingEvent[]
            setUpcomingEvent(data)
        } catch (error) {
            console.log("Error is ", error)
        } finally {
            setLoading(false)
        }
    }

    const fetchPatientRequest = async (params?: {
        search: string
    }) => {
        try {
            setPatientRequestLoading(true)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARDS_PATIENT_REQUEST, { params })
            const data = deserialize(PatientRequest, response.data["events"]) as PatientRequest[]
            setPatientRequest(data)
        } catch (error) {
            console.log("Error is ", error)
        } finally {
            setPatientRequestLoading(false)
        }
    }

    return {
        loading,
        dashboards,
        fetchDashboards,
        dashboardsPagination,
        fetchUpcomingEvents,
        upcomingEvent,
        fetchPatientRequest,
        patientRequest,
        patientRequestLoading
    }
}

export default DashboardService