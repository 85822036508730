import { ColumnProps } from "antd/lib/table";
import React from "react";
import { getFullName } from "../../../shared/utils/dataFormatConverter";
import { getAgeText } from "../../../shared/utils/dateHelpers";
import { PatientRequest } from "../../../models/PatientRequest/patientRequest.model";
import { Tag } from "antd";

const patientRequestColumns: ColumnProps<PatientRequest>[] = [
    {
        title: 'PARENT NAME',
        dataIndex: 'name',
        key: 'name',
        render: (value: string, record: PatientRequest) => <div className="text-capitalize">{getFullName(record?.patient)}</div>
    },
    {
        title: 'SEX',
        dataIndex: 'sex',
        key: 'sex',
        render: (value: string, record: PatientRequest) => <div className="text-capitalize">
            {record.patient?.gender}
        </div>
    },
    {
        title: 'AGE',
        dataIndex: 'age',
        key: 'age',
        render: (value: string, record) => {
            return <>{record?.patient?.dob ? getAgeText(record?.patient?.dob) : record?.patient?.age ? `${record?.patient?.age} Y` : ""}</>
        }
    },
    {
        title: 'Journey',
        dataIndex: 'journeyName',
        key: 'journeyName',
    },
    {
        title: 'CHECKLIST',
        key: 'content',
        dataIndex: 'content'
    },
    {
        title: 'ACTION',
        key: 'status',
        dataIndex: 'status',
        render: (text: string) => (
            <Tag key={text} className={`app-table-tags ${text}`}>
                {text}
            </Tag>
        ),
    },
]


export default patientRequestColumns