
import {serializable, alias, object, primitive} from 'serializr';
import { Patient } from '../Patient/patient.model';

export class PatientRequest { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('content', primitive()))
	content?: string;

	@serializable(alias('order', primitive()))
	order?: number;

	@serializable(alias('patient', object(Patient)))
	patient?: Patient;

	@serializable(alias('step_id', primitive()))
	stepId?: number;

	@serializable(alias('journey_category_id', primitive()))
	journeyCategoryId?: number;

	@serializable(alias('journey_id', primitive()))
	journeyId?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('journey_name', primitive()))
	journeyName?: string;

	@serializable(alias('surgery_date', primitive()))
	surgeryDate?: object;

}