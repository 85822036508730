import { ColumnProps } from "antd/lib/table";
import React from "react";
import { UpcomingEvent } from "../../../models/UpcomingEvent/upcomingEvent.model";
import { getFullName } from "../../../shared/utils/dataFormatConverter";
import { getAgeText } from "../../../shared/utils/dateHelpers";
import { getJourneyStatusDropdownOptions } from "../../../shared/utils/constructDropdownOptions";
import { Form, Formik } from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import { Journey } from "../../../models/Journey/journey.model";


const fetchColumns = (channgeStatus: (status: string, record: Journey) => void) => {
  const columns: ColumnProps<UpcomingEvent>[] = [
    {
      title: 'PARENT NAME',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, record: UpcomingEvent) => <div className="text-capitalize">{getFullName(record?.patient)}</div>
    },
    {
      title: 'SEX',
      dataIndex: 'sex',
      key: 'sex',
      render: (value: string, record: UpcomingEvent) => <div className="text-capitalize">
        {record.patient?.gender}
      </div>
    },
    {
      title: 'AGE',
      dataIndex: 'age',
      key: 'age',
      render: (value: string, record) => {
        return <>{record?.patient?.dob ? getAgeText(record?.patient?.dob) : record?.patient?.age ? `${record?.patient?.age} Y` : ""}</>
      }

    },
    {
      title: 'JOURNEY',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'PRIMARY DATE',
      key: 'surgeryDate',
      dataIndex: 'surgeryDate',
      render: (value: string, ) => {
        return value ?? "-"
      }
    },
    {
      title: "ACTION",
      key: "action",
      width: 50,
      render: (text: string, record) => {
        const statusOptions = getJourneyStatusDropdownOptions(record?.status);
        return (
          <Formik
            initialValues={{
              status: record?.status,
            }}
            onSubmit={() => { }}
          >
            <Form>
              <DropdownField
                className="journey-status-form__status-select"
                name="status"
                options={statusOptions}
                placeHolder="Select status"
                showArrow={true}
                defaultValue={record?.status}
                value={record.status}
                onChange={(value) => {
                  channgeStatus(value, record as Journey)
                }}
              />
            </Form>
          </Formik>
        )
      }
    },
  ]
  return columns
}




export default fetchColumns