
import {serializable, alias, object, list, primitive} from 'serializr';

export class Employees { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('firstname', primitive()))
	firstname?: string;

	@serializable(alias('lastname', primitive()))
	lastname?: string;

	@serializable(alias('display_picture_url', primitive()))
	displayPictureUrl?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('hospital_id', primitive()))
	hospitalId?: number;

	@serializable(alias('employee_role_id', primitive()))
	employeeRoleId?: number;

	@serializable(alias('active', primitive()))
	active?: boolean;

	@serializable(alias('class_name', primitive()))
	className?: string;

	@serializable(alias('title', primitive()))
	title?: string;

}