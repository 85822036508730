import React, { FC, useEffect, useState } from "react";
import "./journeyEvents.scss";
import AppHeader from "../../../../shared/components/AppHeader";
import JourneyEventModal from "./JourneyEventModal";
import JourneyStepService from "../../../../services/JourneyService/journeyStep.service";
import { Journey } from "../../../../models/Journey/journey.model";
import SpinnerLoader from "../../../../shared/components/SpinnerLoader";
import { JourneyTemplateStep } from "../../../../models/JourneyTemplate/journeyTemplateStep.model";
import JourneyCategoryService from "../../../../services/JourneyService/journeyCategory.service";
import { Collapse } from "antd";
import JourneyEventDateInfo from "./JourneyEventDateInfo";
import JourneyEventCard from "./JourneyEventCard";
import { useJourneyContext } from "../../../../context/JourneyContext";
import EmptyState from "../../../../shared/components/EmptyState";
import { useLocation } from "react-router-dom";

interface JourneyEventsProps {
  journeyId?: number;
  journey?: Journey;
  tableLoading?: boolean;
}

const JourneyEvents: FC<JourneyEventsProps> = (props) => {
  const { journeyId, journey, tableLoading } = props;

  const { fetchJourneySteps, journeyStepList, showJourneyStep } = JourneyStepService();

  const location: any = useLocation()

  const { fetchJourneyCategories, journeyCategoryList } =
    JourneyCategoryService();

  const {
    setActiveJourneyCategory,
  } = useJourneyContext()

  useEffect(() => {
    if (!location.state || location.state === '' || journeyCategoryList.length === 0) return

    const categoryId = location.state.category as number
    const stepId = location.state.step as number
    const selectedJourney = journeyCategoryList.find((item) => item.id == categoryId)
    if (!selectedJourney) return

    setActiveJourneyCategory(selectedJourney)

    journeyId && fetchStepDetails(categoryId, stepId).then(() => {
      setShowDetails(true);
    })

  }, [location.state, journeyCategoryList])

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const [currentEvent, setCurrentEvent] = useState<JourneyTemplateStep>({});

  useEffect(() => {
    journeyId && fetchJourneyCategories(journeyId);
  }, [journeyId]);

  const handleEventSearch = (value: string) => {
    journeyId && fetchJourneyCategories(journeyId, value);
  };

  const handleChangeStatus = () => {
    journeyId && fetchJourneyCategories(journeyId);
  };

  const fetchStepDetails = async (categoryId: number, stepId: number) => {
    const step = await showJourneyStep(categoryId, stepId)
    setCurrentEvent(step)
  }

  if (!tableLoading && !journeyCategoryList.length)
    return <div className="journey-events">
      <EmptyState />
    </div>

  return (
    <div className="journey-events">
      <AppHeader
        title={"Journey Timeline"}
        isSearchEnabled={true}
        handleSearch={handleEventSearch}
        buttonText={""}
      />

      {tableLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          <Collapse
            className="journey-events__collapse"
            expandIconPosition="right"
          >
            {journeyCategoryList?.map((journeyCategory, i) => (
              <Collapse.Panel header={journeyCategory?.name} key={i}>
                {journeyCategory?.stepList &&
                  journeyCategory?.stepList?.length > 0
                  ? journeyCategory?.stepList?.map((step) => (
                    <div
                      key={i}
                      className="journey-events__step-item journey-events__row"
                      onClick={() => {
                        setActiveJourneyCategory(journeyCategory)
                        // setCurrentEvent(step);
                        journeyCategory.id && step.id && fetchStepDetails(journeyCategory.id, step.id)
                        setShowDetails(true);
                      }}
                    >
                      <JourneyEventDateInfo journeyStep={step} />
                      <JourneyEventCard journeyStep={step} index={i} />
                    </div>
                  ))
                  : "No steps available"}
              </Collapse.Panel>
            ))}
          </Collapse>
          {/* {duringSurgeryEvents?.events?.length > 0 && (
            <div className="journey-events__parent">
              <div className={"journey-events__row__label " + "during"}>
                {duringSurgeryEvents?.label} Surgery
              </div>
              {duringSurgeryEvents?.events?.map((journeyStep, i) => (
                <div
                  className={`journey-events__row`}
                  key={i}
                  onClick={() => {
                    setCurrentEvent(journeyStep);
                    setShowDetails(true);
                  }}
                >
                  <JourneyEventDateInfo
                    journey={journey}
                    journeyStep={journeyStep}
                    isStepCompleted={isStepCompleted}
                    addDays={addDays}
                    subtractDays={subtractDays}
                  />
                  <JourneyEventCard
                    isStepCompleted={isStepCompleted}
                    journeyStep={journeyStep}
                    index={beforeSurgeryEvents?.events?.length + i}
                  />
                </div>
              ))}
            </div>
          )} */}
        </>
      )}

      <JourneyEventModal
        onStatusChange={handleChangeStatus}
        event={currentEvent}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        journey={journey}
      />
    </div>
  );
};

export default JourneyEvents;
