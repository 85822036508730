import React from "react"
import { EllipsisOutlined } from "@ant-design/icons"
import { Tag, Dropdown, Popover, Col, Row } from "antd"
import { Journey } from "../../../models/Journey/journey.model"
import { getFullName } from "../../../shared/utils/dataFormatConverter"

const popOverContent = (
    name: string,
    imgUrl?: string,
    age?: string,
    gender?: string,
    email?: string,
    phoneNumber?: string
) => <Row className="dashboard-preview">
        {
            imgUrl && (
                <Col>
                    <img
                        className="image-preview"
                        src={imgUrl}
                    />
                </Col>

            )
        }
        <Col className={(imgUrl ? 'ml-3' : '')}>
            <b>{name}</b>
            <div>
                {age} {age !== undefined && ", "} <span className="text-capitalize">{gender}</span>
            </div>
            <div>
                {email}
            </div>
            <div>
                {phoneNumber}
            </div>
        </Col>
    </Row>

const fetchColumns = (menu: JSX.Element, setJourney: React.Dispatch<React.SetStateAction<Journey>>) => {
    return [
        {
            title: "JOURNEY NAME",
            key: "name",
            width: 170,
            ellipsis: true,
            dataIndex: "name",
            render: (text: string) => (
                <p className="text-capitalize ">
                    {text}
                </p>
            ),
        },
        {
            title: "PARENT NAME",
            dataIndex: "guardian",
            width: 120,
            ellipsis: true,
        },
        {
            title: "PATIENT NAME",
            dataIndex: "patient",
            key: "patient",
            width: 170,
            ellipsis: true,
        },
        {
            title: "ASSIGNED DOCTOR",
            dataIndex: "patientName",
            key: "patientName",
            width: 170,
            ellipsis: true,
            render: (text: string, record: Journey) => (
                <p className=" text-capitalize">
                    -
                </p>
            ),
        },
        {
            title: "STATUS",
            key: "status",
            width: 100,
            dataIndex: "status",
            render: (text: string, record: Journey) => (
                <Tag key={record?.id} className={`app-table-tags ${record?.status}`}>
                    {record?.status}
                </Tag>
            ),
        },
        {
            title: null,
            key: "action",
            width: 50,
            render: (text: string, record: Journey) => (
                <div onClick={(event) => event.stopPropagation()}>
                    <Dropdown
                        overlay={menu}
                        placement="bottomLeft"
                        trigger={["click"]}
                        className="manage-groups__details--action"
                        onVisibleChange={(visible) => {
                            setJourney(visible ? record : new Journey())
                        }}
                    >
                        <EllipsisOutlined className="table-menu" />
                    </Dropdown>
                </div>
            ),
        },
    ]
}

export default fetchColumns
