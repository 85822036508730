import React, { FC, useEffect, useState } from "react";
import "./journeyTemplateDetails.scss";
import JourneyTemplateHeader from "./JourneyTemplateHeader";
import JourneyTemplateSteps from "./JourneyTemplateSteps";
import JourneyTemplateService from "../../../services/JourneyTemplateService/journeyTemplate.service";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useJourneyContext } from "../../../context/JourneyContext";
import RedirectBasedOnThePermission from "../../../shared/components/RedirectBasedOnThePermission";

interface JourneyTemplateDetailsProps { }

const JourneyTemplateDetails: FC<JourneyTemplateDetailsProps> = (props) => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const { showJourneyTemplate, journeyTemplate, journeyLoading } =
    JourneyTemplateService();

  const {
    setActiveJourneyCategoryStep,
    setActiveJourneyCategory,
    setJourneyCategories,
  } = useJourneyContext();

  useEffect(() => {
    return () => {
      setActiveJourneyCategory(undefined)
      setActiveJourneyCategoryStep(undefined)
      setJourneyCategories([])
    }
  }, [])

  useEffect(() => {
    setEditMode(history?.location?.state === "edit" || id === "new");
    if (id !== "new") {
      showJourneyTemplate(id);
    }
  }, [history.location.state]);

  return (
    <div className="app-wrapper journey-template-details">
      <RedirectBasedOnThePermission
        entityName={"journeyTemplateAccess"}
      >
        <JourneyTemplateHeader
          refreshTemplate={() => showJourneyTemplate(id)}
          template={journeyTemplate}
          loading={journeyLoading}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        {id !== "new" && <JourneyTemplateSteps editMode={editMode} />}

      </RedirectBasedOnThePermission>
    </div>
  );
};

export default JourneyTemplateDetails;
