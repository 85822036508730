import React, { FC, useState } from "react";
import "./hospitalInfoTabs.scss";
import SearchComponent from "../../../../shared/components/SearchComponent";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { Tabs } from "antd";
import Departments from "../Departments";
import DepartmentService from "../../../../services/HospitalService/department.service";
import LocationService from "../../../../services/HospitalService/location.service";
import DepartmentForm from "../Departments/DepartmentForm";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthState } from "../../../../store/reducers/authReducer";
import { useParams } from "react-router-dom";
import LocationForm from "../Locations/LocationForm";
import { statusMeta } from "../../../../shared/utils/statusMetas";
import PermissionBasedRestriction from "../../../../shared/components/PermissionBasedRestriction";
const { TabPane } = Tabs;

interface HospitalInfoTabsProps extends AuthState { }

const HospitalInfoTabs: FC<HospitalInfoTabsProps> = (props) => {
  const { id } = useParams();

  const {
    fetchDepartments,
    departmentList,
    currentDepartmentPage,
    totalDepartmentItems,
  } = DepartmentService();
  const {
    fetchLocations,
    locationTableLoading,
    locationList,
    currentLocationPage,
    totalLocationItems,
  } = LocationService();

  const [selectedKey, setSelectedKey] = useState<string>("department");
  const [showDepartmentForm, setShowDepartmentForm] = useState<boolean>(false);
  const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const refreshDepartments = () =>
    fetchDepartments(id, searchText, currentDepartmentPage, 9).then();

  const refreshLocations = () => fetchLocations(id, searchText).then();

  const handleTabChange = (key: string) => {
    setSelectedKey(key);
  };

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);

    if (selectedKey === "department")
      fetchDepartments(id, searchText, 1, 9).then();
    else fetchLocations(id, searchText).then();
  };

  const handlePageChange = (page: number, pageSize?: number) =>
    selectedKey === "department"
      ? fetchDepartments(id, searchText, page, pageSize).then()
      : fetchLocations(id, searchText, page, pageSize).then();

  return (
    <PermissionBasedRestriction
      entityName="hosDepartmentAccess"
      entityPermission="read"
    >
      <div className="hospital-info-tabs">
        <div className="hospital-info-tabs__action">
          <SearchComponent onSearch={handleSearch} searchValue={searchText} setSearchValue={setSearchText} />
          <PermissionBasedRestriction
            entityName="hosDepartmentAccess"
            entityPermission="create"
          >
            <ButtonComponent
              type="primary"
              text={`Add ${selectedKey === "department" ? "Department" : "Location"
                }`}
              onClick={() => {
                selectedKey === "department"
                  ? setShowDepartmentForm(true)
                  : setShowLocationForm(true);
              }}
              className="hospital-info-tabs__add"
            />
          </PermissionBasedRestriction>
        </div>


        <Tabs defaultActiveKey="department" onChange={handleTabChange}>
          <TabPane tab="Departments" key="department">
            <Departments
              departmentList={departmentList ?? []}
              currentDepartmentPage={currentDepartmentPage}
              totalDepartmentItems={totalDepartmentItems}
              refreshDepartments={refreshDepartments}
              handlePageChange={handlePageChange}
            />
          </TabPane>
          {/* <TabPane tab="Locations" key="location">
          <Locations
            refreshLocations={refreshLocations}
            locationTableLoading={locationTableLoading}
            locationList={locationList}
            handlePageChange={handlePageChange}
            currentLocationPage={currentLocationPage}
            totalLocationItems={totalLocationItems}
          />
        </TabPane> */}
        </Tabs>


        <DepartmentForm
          showForm={showDepartmentForm}
          setShowForm={setShowDepartmentForm}
          department={{}}
          refreshList={refreshDepartments}
        />
        <LocationForm
          showForm={showLocationForm}
          setShowForm={setShowLocationForm}
          location={{}}
          refreshList={refreshLocations}
          statusMeta={statusMeta}
        />
      </div>
    </PermissionBasedRestriction>
  );
};

export default AuthContainer(HospitalInfoTabs);
