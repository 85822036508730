
import {serializable, alias, object, list, primitive} from 'serializr';
import { Permissions } from '../Permissions/permissions.model';

export class CustomAccess { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('employee_role_name', primitive()))
	employeeRoleName?: string;

	@serializable(alias('hospital_access', object(Permissions)))
	hospitalAccess?: Permissions;

	@serializable(alias('hos_department_access', object(Permissions)))
	hosDepartmentAccess?: Permissions;

	@serializable(alias('staff_access', object(Permissions)))
	staffAccess?: Permissions;

	@serializable(alias('caregiver_access', object(Permissions)))
	caregiverAccess?: Permissions;

	@serializable(alias('patient_access', object(Permissions)))
	patientAccess?: Permissions;

	@serializable(alias('journey_access', object(Permissions)))
	journeyAccess?: Permissions;

	@serializable(alias('journey_template_access', object(Permissions)))
	journeyTemplateAccess?: Permissions;

	@serializable(alias('article_access', object(Permissions)))
	articleAccess?: Permissions;

	@serializable(alias('faq_access', object(Permissions)))
	faqAccess?: Permissions;

	@serializable(alias('tags_access', object(Permissions)))
	tagsAccess?: Permissions;

	@serializable(alias('chat_access', object(Permissions)))
	chatAccess?: Permissions;

}