import React, { FC } from "react";
import AuthContainer from "../../store/container/AuthContainer";
import { AuthReducerProps } from "../../store/reducers/authReducer";
import ClinicalStaffDashboard from "./ClinicalStaffDashboard";
import HospitalAdminDashboard from "./HospitalAdminDashboard";

interface DashboardProps extends AuthReducerProps {

}

const Dashboard: FC<DashboardProps> = ({ user }) => {
    return (
        <div className='app-wrapper'>
            {user?.role === 'Clinical Staff' ? <ClinicalStaffDashboard /> : <HospitalAdminDashboard />}
        </div>
    )
}

export default AuthContainer(Dashboard);
